import arrowRight from './arrow-right.png';
import React from 'react';

export const ArrowRight = () => (
  <img className="icon-arrow-right" src={arrowRight} alt="Right arrow" />
);

export function ArrowLeft({
  className,
  ...rest
}: JSX.IntrinsicAttributes &
  React.ClassAttributes<HTMLImageElement> &
  React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      {...rest}
      className={`icon-arrow-left transform rotate-180 ${className || ''}`}
      src={arrowRight}
      alt="Left arrow"
    />
  );
}
