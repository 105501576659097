import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useToken } from 'context/token-context';
import { useUser } from 'context/user-context';
import { forgetRememberedClub } from 'utils/feature-remember-club';

export default function Logout({ redirectTo }: { redirectTo?: string }) {
  const history = useHistory();
  const { logout } = useToken();
  const { clearUser } = useUser(true);

  useEffect(() => {
    logout();
    clearUser();
    forgetRememberedClub();
    history.push(
      redirectTo
        ? `/login?returnUrl=${encodeURIComponent(redirectTo)}`
        : '/login'
    );
    localStorage.clear();
  });

  return null;
}
