import styled from 'styled-components/macro';

type Props = {
  variant: 'success';
};

export const Alert = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 15px 20px;
`;
