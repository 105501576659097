import React from 'react';

type Props = { flag: string };

export function hasFeatureFlag(flag: string) {
  const flags = (process.env.REACT_APP_FEATURE_FLAGS || '')
    .split(',')
    .map(x => x.trim())
    .filter(x => !!x);

  return flags.includes(flag);
}

export function FeatureFlag({
  flag,
  children,
}: React.PropsWithChildren<Props>) {
  if (hasFeatureFlag(flag)) {
    return <>{children}</>;
  }

  return null;
}
