import React, { useState, useEffect } from 'react';

import { useUser } from 'context/user-context';
import ErrorBoundary from 'pages/app/error-boundary';
import { Stack } from 'ui';

import * as Sentry from '@sentry/browser';

export function DeveloperErrorPage() {
  const { userInfo } = useUser();
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  function sendError() {
    Sentry.captureException(new Error('This is a custom error'));
  }

  function report() {
    Sentry.showReportDialog({
      title: 'Uh-oh, something went wrong',
      user: {
        name: userInfo ? userInfo.name : '',
        email: userInfo ? userInfo.email : '',
      },
    });
  }

  return (
    <div className="container mx-auto">
      <h1>Error page</h1>

      <Stack>
        <button onClick={sendError}>Send error to Sentry</button>
        <button onClick={report}>Show report dialog</button>
      </Stack>
      <ErrorBoundary>
        <Component />
      </ErrorBoundary>
    </div>
  );
}

const Component = () => {
  const [state, setState] = useState(0);

  useEffect(() => {
    if (state === 1) {
      throw new Error('Inside error boundary');
    }
  }, [state]);

  return (
    <button onClick={() => setState(state + 1)}>
      Crash inside error boundary
    </button>
  );
};
