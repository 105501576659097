import React from 'react';

export * from './forms/checkbox-field';
export * from './forms/email-field';
export * from './forms/input-field';
export * from './forms/password-field';

export function FormMessage({
  variant,
  children,
}: React.PropsWithChildren<{ variant: string }>) {
  return <p className="bg-success p-4">{children}</p>;
}

export function FormError({ text }: { text?: string }) {
  if (!text) {
    return null;
  }

  return (
    <div className="alert alert-danger form-error" role="alert">
      <span className="sr-only">Error:</span>
      {text}
    </div>
  );
}
