import { useEffect, useState, useCallback } from 'react';
import { AxiosError } from 'axios';

import { _get } from 'external/api';

type QueryOptions = {
  shouldFetch?: boolean;
  clearOnRefetch?: boolean;
};

export function useQuery<T>(url: string, options: QueryOptions = {}) {
  const { shouldFetch = true, clearOnRefetch = false } = options;
  const [state, setState] = useState<{
    result: T | undefined;
    isLoading: boolean;
    error: any;
  }>({ result: undefined, isLoading: shouldFetch || false, error: null });

  const fetch = useCallback(
    url => {
      if (!shouldFetch) {
        return Promise.resolve(undefined);
      }

      setState(prevState => ({
        isLoading: true,
        error: null,
        result: clearOnRefetch ? undefined : prevState.result,
      }));

      return _get<T>(url)
        .then(r => {
          setState(prevState => ({
            ...prevState,
            result: r.data,
            isLoading: false,
          }));
        })
        .catch((e: AxiosError) => {
          setState(prevState => ({ ...prevState, isLoading: false, error: e }));
        });
    },
    [shouldFetch, clearOnRefetch]
  );

  useEffect(() => {
    fetch(url);
  }, [fetch, url]);

  // throw this error so that the main error boundary will log ous out!
  if (state.error) {
    const e = state.error;

    if (
      e.isAxiosError ||
      !e.response ||
      (e.response && e.response.status === 401)
    ) {
      throw e;
    }
  }

  return {
    ...state,
    fetch: () => fetch(url),
  };
}
