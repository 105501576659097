import React, { useContext, useReducer, useCallback, useEffect } from 'react';

import { getMe } from 'external/api';
import { MeViewModel, MyPersonaViewModel } from 'external/api/types';
import { Api } from 'external/api/common';

import { noExceptParse } from 'HelperFunctions/noExceptParse';
import { firstOrDefault } from 'common/linq';
import { logger } from 'utils/logging';

type UserContextProps = {
  isLoggedIn: boolean;
  activeUserId: number;
  userInfo: MeViewModel;
  currentRole?: MyPersonaViewModel;
  setUser: (user: MeViewModel) => void;
  setRole: (roleId: number) => void;
  clearUser: () => void;
  isUpdatingUserInfo: boolean;
  getUserInfo: () => Promise<MeViewModel>;
};

const UserContext = React.createContext<UserContextProps | undefined>(
  undefined
);

type State = {
  userInfo?: MeViewModel;
  currentRole?: MyPersonaViewModel;
  isUpdating: boolean;
  hasError: boolean;
};
type Action =
  | { type: 'setuser'; payload: MeViewModel }
  | { type: 'setrole'; payload: number }
  | { type: 'logout' }
  | { type: 'getUserInfo_started' }
  | { type: 'getUserInfo_success'; payload: MeViewModel };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setuser':
      const userInfo = action.payload;
      return {
        ...state,
        userInfo,
        currentRole:
          userInfo.memberOf?.length > 0 ? userInfo.memberOf[0] : undefined,
      };

    case 'setrole':
      const role = state.userInfo
        ? firstOrDefault(
            state.userInfo.memberOf,
            (m) => m.id === action.payload
          )
        : undefined;
      return {
        ...state,
        currentRole: role || undefined,
      };

    case 'logout':
      return {
        userInfo: undefined,
        currentRole: undefined,
        isUpdating: false,
        hasError: false,
      };

    case 'getUserInfo_started':
      return {
        ...state,
        isUpdating: true,
      };

    case 'getUserInfo_success': {
      const userInfo = action.payload;

      // if we didn't even have a role (let's say you just finished setup)
      // or if you did, but it no longer exists
      let currentRole = state.currentRole;
      let activeUserId = !!currentRole ? currentRole.id : 0;
      const { memberOf = [] } = userInfo;
      if (
        !currentRole ||
        memberOf.findIndex((m) => m.id === activeUserId) === -1
      ) {
        if (memberOf.length === 0) {
          currentRole = undefined;
        } else {
          currentRole = memberOf[0];
        }
      } else {
        // simply update in case there were changes on the server
        currentRole = memberOf.find((m) => m.id === activeUserId);
      }

      return {
        ...state,
        userInfo,
        currentRole,
        isUpdating: false,
        hasError: false,
      };
    }

    default:
      return state;
  }
}

function reducerWithExtras(prevState: State, action: Action) {
  const state = reducer(prevState, action);
  localStorage.setItem('user', JSON.stringify(state));

  const { userInfo, currentRole } = state;
  if (!userInfo) {
    logger.clearUser();
  } else {
    logger.setUser({
      email: userInfo.email,
      roleId: currentRole ? currentRole.id : 0,
      role: currentRole ? currentRole.type : '',
    });
  }

  Api.setRole(state.currentRole ? state.currentRole.id : 0);

  return state;
}

const lastState = noExceptParse<State>(localStorage.getItem('user'));

function UserProvider({
  children,
  initialState,
}: React.PropsWithChildren<{ initialState?: State }>) {
  const [state, dispatch] = useReducer(
    reducerWithExtras,
    initialState ||
      lastState || {
        isUpdating: false,
        hasError: false,
      }
  );

  const { userInfo, currentRole, isUpdating } = state;

  const getUserInfo = useCallback(async function () {
    dispatch({ type: 'getUserInfo_started' });

    const response = await getMe();
    dispatch({ type: 'getUserInfo_success', payload: response.data });

    return response.data;
  }, []);

  const value: UserContextProps = {
    isLoggedIn: !!userInfo,
    activeUserId: currentRole ? currentRole.id : 0,
    currentRole,
    userInfo: userInfo || {
      name: '',
      email: '',
      avatarUrl: '',
      memberOf: [],
      notifications: [],
      isConfirmed: false,
      unread: 0,
      hasFacebook: false,
    },
    isUpdatingUserInfo: isUpdating,
    setRole(roleId: number) {
      dispatch({ type: 'setrole', payload: roleId });
    },
    setUser(user: MeViewModel) {
      dispatch({ type: 'setuser', payload: user });
    },
    clearUser() {
      dispatch({ type: 'logout' });
    },
    getUserInfo,
  };

  useEffect(() => {
    if (lastState && lastState.userInfo) {
      const { userInfo, currentRole } = lastState;

      logger.setUser({
        email: userInfo.email,
        roleId: currentRole ? currentRole.id : 0,
        role: currentRole ? currentRole.type : '',
      });
    }
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

/**
 * Retrieve current user from context.
 * If `allowFail` is set to true, the function will not throw in case the user
 * is not logged in.
 *
 * @param {boolean} [allowFail]
 * @returns context
 */
function useUser(allowFail?: boolean) {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('This component is not under UserProvider');
  }

  if (context.isLoggedIn && !context.userInfo) {
    if (!allowFail) {
      console['error']("User should be logged in, but it isn't");
      throw Error('INVALID_USER');
    }
  }

  return context;
}

export { UserProvider, useUser };
