// Importing CSS
import './tailwind.generated.css';

// must be first
import 'react-app-polyfill/ie9';
import type {} from 'styled-components/cssprop';

import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useUser } from 'context/user-context';
import { logger } from 'utils/logging';
import { Loading } from 'ui/Loading';

import Contexts from './context';
import AuthenticatedApp from './authenticated-app';
import reportWebVitals from './reportWebVitals';

logger.init();

const AnonymousApp = React.lazy(
  () => import(/*webpackChunkName: "anonymous-app"*/ './anonymous-app')
);

function Root() {
  const user = useUser(true);

  if (user.isLoggedIn) {
    return <AuthenticatedApp />;
  }

  return <AnonymousApp />;
}

function App() {
  useEffect(() => {
    if (navigator.userAgent.match(/Trident.*rv:11\./)) {
      document.getElementsByTagName('body')[0].className += ' ie11';
    }

    document.getElementsByTagName('body')[0].className += ' non-ie11';
  }, []);

  return (
    <Contexts>
      <Suspense fallback={<Loading delayed />}>
        <Root />
      </Suspense>
    </Contexts>
  );
}

// Wrap the rendering in a function:
const render = (Component: any) => {
  ReactDOM.render(<Component />, document.getElementById('root'));
};

// Render once
render(App);

// PREVIEW
// switch to light mode
// document.getElementsByTagName('html')[0].classList.add('__light');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
