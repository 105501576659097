import styled from 'styled-components';

import defaultUserLogo from '../Images/empty-image.svg';

import { SpacingType } from './atoms/types';

export * from './atoms/alert/alert';
export * from './atoms/box/box';
export * from './atoms/button/button';
export * from './atoms/inline/inline';
export * from './atoms/link-button/link-button';
export * from './atoms/logo/logo';
export * from './atoms/stack/stack';

export * from './external-link';
export * from './form-fields';
export * from './grid-layout';
export * from './icons';
export * from './Loading';
export * from './page';
export * from './ProgressBar';
export * from './social-icons/index';

const HorizontalGap = styled.div`
  margin: 0 auto;
`;

const VerticalGap = styled.div`
  margin: auto 0;
`;

const VerticalSpace = styled.section<{ space?: SpacingType }>`
  min-height: var(${props => `--space-${props.space || 'medium'}`});
`;

// const defaultUserLogo = 'https://static.gigpin.com/images/user_placeholder.jpg';

export { HorizontalGap, VerticalGap, VerticalSpace, defaultUserLogo };
