import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { MyPersonaViewModel } from 'external/api';
import IconDashboard from 'pages/app/navbar/icon-dashboard';
import IconIntegrate from 'pages/app/navbar/icon-integrate';
import { IconRequests, IconSearch } from '../navbar/role-menu';
import { FeatureFlag } from 'common/feature-flags';
import IconOffers from 'ui/icons/icon-offers';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  font-size: 0.75rem;

  & + & {
    margin-top: 20px;
  }

  svg,
  img {
    margin-right: 10px;
    width: 20px;
  }

  &:hover {
    color: var(--accent);
  }
`;

type Props = {
  currentRole: MyPersonaViewModel;
};

export function MobileMenu(props: Props) {
  const {
    currentRole: { type },
  } = props;

  return (
    <section className="my-4 ml-5">
      {type === 'band' && (
        <>
          <StyledLink to="/">
            <IconDashboard /> Dashboard
          </StyledLink>
          <StyledLink to="/artist-requests">
            <IconRequests /> Requests
          </StyledLink>
          <FeatureFlag flag="venue-search">
            <StyledLink to="/venue-search">
              <IconSearch />
              Find venues
            </StyledLink>
          </FeatureFlag>
        </>
      )}

      {type === 'club' && (
        <>
          <StyledLink to="/">
            <IconDashboard /> Dashboard
          </StyledLink>
          <StyledLink to="/requests">
            <IconOffers /> Requests
          </StyledLink>
          <StyledLink to="/club-integration">
            <IconIntegrate /> Integrate
          </StyledLink>
        </>
      )}
    </section>
  );
}
