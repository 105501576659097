import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { MyPersonaViewModel } from 'external/api';
import { RoleLogo } from 'ui/atoms/logo/logo';

const RolesTitle = styled.h5`
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    padding-top: 20px;
    padding-left: 20px;
  }
`;

const RolesList = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 0;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 20px;
    min-height: 60px;

    > *:last-child {
      padding-right: 20px;
    }
  }
`;

const RoleListItem = styled.li`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export default function Roles({
  title,
  roles,
  onSwitch,
}: {
  title: string;
  onSwitch: (id: number) => void;
  roles: MyPersonaViewModel[];
}) {
  if (!roles || !roles.length) {
    return null;
  }

  return (
    <>
      <RolesTitle>
        {title} ({roles.length})
      </RolesTitle>

      <RolesList>
        {roles.map((role) => (
          <RoleListItem key={role.id} onClick={() => onSwitch(role.id)}>
            <Link to="/" title={role.name}>
              <RoleLogo type={role.type} logoUrl={role.logoThumb} />
            </Link>
          </RoleListItem>
        ))}
      </RolesList>
    </>
  );
}
