import React, { FunctionComponent } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import 'styled-components/macro';

import { FieldWrapper, Input, FieldLabel } from './common';
import FieldError from './field-error';

export type PasswordFieldProps = {
  className?: string;
  label?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

type Props = PasswordFieldProps &
  FieldRenderProps<string | number | string[], HTMLInputElement>;

export const PasswordFieldComponent: FunctionComponent<Props> = ({
  className,
  children,
  label,
  input,
  meta,
  ...rest
}) => (
  <FieldWrapper id={`field-${input.name}`}>
    <label aria-label={!label ? `${input.name} input field` : undefined}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <Input
        {...rest}
        {...input}
        {...meta}
        type="password"
        name={input.name}
        css={''}
      />
    </label>
    <FieldError name={input.name} />
  </FieldWrapper>
);

export function PasswordField({
  name = 'password',
  ...props
}: { name?: string } & PasswordFieldProps) {
  return (
    <Field {...props} name={name} component={PasswordFieldComponent as any} />
  );
}
