import React from 'react';

import { FacebookPagesData, FacebookAccountDto } from 'external/api';

import './facebook-pages.css';

export default function FacebookPages({
  pages,
  onClick,
}: {
  pages: FacebookPagesData;
  onClick: (page: FacebookAccountDto) => any;
}) {
  return (
    <ul className="facebook-pages">
      {pages.accounts.data.map(page => (
        <li
          key={page.id}
          className="facebook__page"
          onClick={e => {
            onClick(page);
          }}
        >
          <img src={page.picture.data.url} alt="Facebook page logo" />
          <span>{page.name}</span>
        </li>
      ))}
    </ul>
  );
}
