import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { routes } from 'routes';

export default function AuthenticatedApp() {
  return (
    <>
      <BrowserRouter children={routes} />
    </>
  );
}
