import React, { FunctionComponent } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

import { FieldWrapper, Input, FieldLabel } from './common';
import FieldError from './field-error';

export type EmailFieldProps = {
  className?: string;
  label?: string;
  placeholder?: string;
  autoCapitalize?: string;
  autoCorrect?: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
};

export const EmailFieldComponent: FunctionComponent<
  EmailFieldProps & FieldRenderProps<string, HTMLInputElement>
> = ({
  className,
  children,
  label,
  autoCorrect = 'off',
  autoCapitalize = 'none',
  input,
  meta,
  ...rest
}) => (
  <FieldWrapper id={`field-${input.name}`}>
    <label aria-label={!label ? `${input.name} input field` : undefined}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <Input
        {...rest}
        {...input}
        {...meta}
        type="email"
        autoCorrect={autoCorrect}
        autoCapitalize={autoCapitalize}
        name={input.name}
      />
    </label>

    <FieldError name={input.name} />
  </FieldWrapper>
);

export function EmailField({
  name = 'email',
  ...props
}: { name?: string } & EmailFieldProps) {
  return (
    <Field {...props} name={name} component={EmailFieldComponent as any} />
  );
}
