import React, { PureComponent } from 'react';
import { useLocation } from 'react-router';
import { Location } from 'history';
import * as Sentry from '@sentry/browser';
import { Link } from 'react-router-dom';

import { MeViewModel } from 'external/api/types';
import { useUser } from 'context/user-context';
import Logout from 'pages/app/logout';
import ErrorPage from 'pages/error-page';
import { Button } from 'ui/atoms/button/button';
import { Stack } from 'ui/atoms/stack/stack';

import { MainContent } from './main-content';

enum ErrorState {
  None,

  InvalidUser,
  ServerUnavailable,
  RedirectAfterError,
  Error,
}

type Props = {
  location: Location<any>;
  userInfo: MeViewModel;
};

type State = {
  errorState: ErrorState;
  eventId: string | null;
};

class ErrorBoundaryImplementation extends PureComponent<Props, State> {
  state = {
    errorState: ErrorState.None,
    eventId: null,
  };

  static getDerivedStateFromError(error: any) {
    if (error.message === 'INVALID_USER') {
      return { errorState: ErrorState.InvalidUser };
    }

    if (!error.response) {
      return { errorState: ErrorState.ServerUnavailable };
    } else if (error.response && error.response.status === 401) {
      return { errorState: ErrorState.RedirectAfterError };
    }

    return { errorState: ErrorState.Error };
  }

  componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtra('componentStack', errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  showFeedbackDialog = () => {
    const { userInfo } = this.props;
    if (Sentry.lastEventId()) {
      Sentry.showReportDialog({
        title: 'Uh-oh, something went wrong',
        user: {
          name: userInfo ? userInfo.name : '',
          email: userInfo ? userInfo.email : '',
        },
      });
    }
  };

  render() {
    const { errorState, eventId } = this.state;
    const { children } = this.props;

    switch (errorState) {
      case ErrorState.InvalidUser:
        return <Logout redirectTo="/" />;

      case ErrorState.RedirectAfterError:
        return <Logout redirectTo={this.props.location.pathname} />;

      case ErrorState.ServerUnavailable:
        return (
          <MainContent>
            <ErrorPage>
              <Stack>
                GigPin is currently unavailable, please check later.
                {!!eventId && (
                  <Button variant="primary" onClick={this.showFeedbackDialog}>
                    Submit report
                  </Button>
                )}
              </Stack>
            </ErrorPage>
          </MainContent>
        );
      case ErrorState.Error:
        return (
          <MainContent>
            <ErrorPage>
              <p>
                You can try <Link to="/logout">logging out</Link> or going to{' '}
                <Link to="/">dashboard</Link>.
              </p>

              <Stack>
                {!!eventId && (
                  <Button variant="primary" onClick={this.showFeedbackDialog}>
                    Submit report
                  </Button>
                )}
              </Stack>
            </ErrorPage>
          </MainContent>
        );
    }

    return children;
  }
}

export default function ErrorBoundary(props: React.PropsWithChildren<{}>) {
  const location = useLocation();
  const { userInfo } = useUser();

  return (
    <ErrorBoundaryImplementation
      {...props}
      location={location}
      userInfo={userInfo}
    />
  );
}

/* <p>
  You can try <Link to="/logout">logging out</Link> or going to{' '}
  <Link to="/">dashboard</Link>.
</p> */
