import React from 'react';

export default function IconNotification() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0153 18.6113L17.8583 15.7922V11.8399H17.8427C17.6964 7.89841 14.7015 3.84451 10.8537 3.23422C11.2161 2.97954 11.4546 2.56027 11.4546 2.08342C11.4546 1.3065 10.8246 0.676575 10.0469 0.676575C9.2692 0.676575 8.63987 1.3065 8.63987 2.08342C8.63987 2.5352 8.85597 2.93213 9.18723 3.18952C5.18703 3.61963 2.0309 7.79003 1.88051 11.8399H1.86561V15.7922L0.707886 18.6113H19.0153Z"
        fill="currentColor"
      />
      <path
        d="M6.89875 19.2887C7.34924 20.4916 8.50086 21.3505 9.86113 21.3505C11.2214 21.3505 12.3724 20.4909 12.8235 19.2887H6.89875Z"
        fill="currentColor"
      />
    </svg>
  );
}
