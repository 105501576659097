import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { IconMobileMenu } from './icon-mobile-menu';

export const OpenButtonMobile = styled(IconMobileMenu)`
  color: #b0b0b0;
`;

export const OpenButton = styled.button<{ open: boolean }>`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 15px;

  ${(props) =>
    props.open
      ? css`
          left: calc(414px + 70px);
          filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
        `
      : css`
          background-color: #282828;
          left: 60px;
        `}

  width: 30px;
  padding-left: 15px;
  height: 30px;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;

  @media screen and (min-width: 0) and (max-width: 767px) {
    display: none;
  }
`;

export const Sidebar = styled.div<{ open: boolean }>`
  opacity: ${(props) => (props.open ? '1' : '0')};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};

  position: absolute;
  left: ${(props) => (props.open ? '70px' : '60px')};
  top: 0;
  background-color: #1a1a1c;
  height: 100%;
  padding: 10px 20px 20px 40px;
  width: 414px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    left: unset;
    right: 0;
    width: 100%;
    top: ${(props) => (props.open ? '70px' : '80px')};
    height: calc(100% - 70px);

    padding: 0;

    > *:last-child {
      padding-bottom: 100px;
    }
  }

  > h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    /* dash offers small text */
    color: var(--grey-blue);
  }
`;

export const AvatarUrl = styled.img`
  width: 20px;
  border-radius: 50%;
`;

export const MenuLinks = styled.ul`
  list-style-type: none;
  padding-left: 0;

  > * {
    margin-bottom: 10px;
  }

  @media screen and (min-width: 0) and (max-width: 767px) {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    padding-top: 20px;
    padding-left: 20px;
  }
`;

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: var(--grey-blue);

  :hover {
    color: var(--accent);
  }

  :hover svg {
    stroke: var(--accent);
  }

  & svg {
    stroke: var(--grey-blue);
    margin-right: 10px;
  }
`;

export const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
`;

export const UserName = styled.span`
  color: var(--accent);
`;

export const SignedIn = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  min-height: 40px;
  line-height: 16px;
  color: var(--grey-blue);
  font-size: 0.8em;

  > img {
    margin-left: 10px;
  }

  ${UserName} {
    margin-left: 5px;
  }

  @media screen and (max-width: 767px) {
    background-color: black;
    width: 100%;
    padding: 10px 20px;
  }
`;
