import { _post } from './common';

export const forgotPassword = (email: string) => {
  return _post(`user/forgot-password`, {
    email,
  });
};

export interface ResetPasswordRequest {
  email: string;
  password: string;
  confirmPassword: string;
  token: string;
}

export const resetPassword = (data: ResetPasswordRequest) => {
  return _post(`user/reset-password`, data);
};
