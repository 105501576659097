import './forms.css';

import React, { useState } from 'react';
import {
  Form as ReactFinalForm,
  FormSpy,
  FormProps,
  FormRenderProps,
  withTypes,
} from 'react-final-form';
import { FORM_ERROR, FormApi } from 'final-form';
import styled from 'styled-components/macro';
import { ObjectSchema } from 'yup';
import cn from 'classnames';

import { FormError } from 'ui/form-fields';
import { Loading } from 'ui/Loading';
import { formatApiError } from 'common';

window['showFormHelper'] = false; // process.env.NODE_ENV === 'development';

function applyFieldError(oldError: {}, path: string, value: string) {
  const newError = { ...oldError };

  if (path.indexOf('.') === -1) {
    newError[path] = value;
  } else {
    if (path.indexOf('[') !== -1) {
      // debugger;
    }

    const parts = path.split('.').map((part) => part.trim());
    let current: {} = newError;
    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        // last one is the setter
        current[part] = value;
      } else {
        if (current.hasOwnProperty(part)) {
          current = current[part];
        } else {
          current[part] = {};
          current = current[part];
        }
      }
    });
  }

  return newError;
}

type Props<T extends any> = FormProps<T> & {
  className?: string;
  autoComplete?: string;
  validationSchema?: ObjectSchema<any>;
};

export default function Form<T extends any>({
  className,
  onSubmit,
  children,
  autoComplete,
  validate,
  validationSchema,
  ...rest
}: Props<T>) {
  async function submit(values: T, form: FormApi<T>, callback: any) {
    try {
      await onSubmit(values, form, callback);
    } catch (e) {
      return { [FORM_ERROR]: formatApiError(e) };
    }

    return undefined;
  }

  function validateValues(values: T) {
    if (!!validationSchema) {
      try {
        validationSchema.validateSync(values, { abortEarly: false });
      } catch (error) {
        if (!!error && !!error.inner) {
          return error.inner.reduce((accumulator: any, e: any) => {
            return applyFieldError(accumulator, e.path, e.message);
          }, {});
        }
      }
    } else if (!!validate) {
      return validate(values);
    }

    return {};
  }

  return (
    <ReactFinalForm<T> onSubmit={submit} validate={validateValues} {...rest}>
      {(renderProps: FormRenderProps<object>) => (
        <form
          className={cn(className, 'relative')}
          onSubmit={renderProps.handleSubmit}
          autoComplete={autoComplete}
        >
          {renderProps.hasSubmitErrors && (
            <FormError text={renderProps.submitError || 'An error ocurred'} />
          )}

          {typeof children === 'function'
            ? (children as any)(renderProps)
            : children}

          {renderProps.submitting && <Loading />}

          <DevelopmentFormHelper />
        </form>
      )}
    </ReactFinalForm>
  );
}

const StyledDevForm = styled.pre`
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 300px;
  overflow: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  opacity: 0;
  padding: 5px;
  box-shadow: 1px 1px 10px rgba(100, 100, 100, 0.5);
  font-family: Consolas, 'Courier New', Courier, monospace;
  font-size: 12px;
`;

const Anchor = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 10px;
  width: 100px;
  height: 20px;

  :hover > * {
    opacity: 1;
  }
`;

function DevelopmentFormHelper() {
  const [show] = useState(window['showFormHelper']);

  if (process.env.NODE_ENV !== 'development' || !show) {
    return <></>;
  }

  return (
    <FormSpy>
      {({ values }) => (
        <Anchor>
          DEV FORM
          <StyledDevForm>{JSON.stringify(values, null, 2)}</StyledDevForm>
        </Anchor>
      )}
    </FormSpy>
  );
}

export { withTypes };
