import React from 'react';

export default function IconOffers() {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 40 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9992 23.9699L30.7992 10.8699H25.1992V0.569946H14.7992V10.8699H9.19922L19.9992 23.9699Z"
        fill="currentColor"
      />
      <path
        d="M40 33.27H0V20.87H3.6V29.67H36.4V20.87H40V33.27Z"
        fill="currentColor"
      />
    </svg>
  );
}
