export function getTokenFromRedirectUrl() {
  const href = window.location.href;
  const access_token_substr = 'access_token=';
  let index = href.indexOf(access_token_substr);
  if (index === -1) {
    return '';
  }

  let token = href.substr(index + access_token_substr.length);
  index = token.indexOf('&');
  if (index !== -1) token = token.substr(0, index);

  return token;
}

export function getFacebookInfoFromRedirectUrl() {
  const accessToken = getTokenFromRedirectUrl();

  let expiresIn = 0;
  const matches = window.location.href.match(/.*expires_in=([0-9]*).*/i);
  if (matches && matches.length === 2) {
    expiresIn = Number(matches[1]);
  }

  return { accessToken, expiresIn };
}
