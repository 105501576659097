import React from 'react';

export default function IconSignout(props: any) {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8708 22.9779H3.87678C2.6001 22.9779 1.55859 21.8324 1.55859 20.4511V13.3087C1.55859 11.8937 2.6001 10.7819 3.87678 10.7819H15.8708C17.1475 10.7819 18.189 11.9273 18.189 13.3087V20.4511C18.189 21.8324 17.1475 22.9779 15.8708 22.9779Z"
        strokeWidth="1.168"
        strokeMiterlimit="10"
      />
      <path
        d="M3.23828 10.7819V8.01926C3.23828 4.34698 6.1948 1.3822 9.85686 1.3822C13.5189 1.3822 16.4754 4.31329 16.4754 7.98557V10.7482"
        strokeMiterlimit="10"
      />
      <path
        d="M9.85938 14.915V19.3309"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
