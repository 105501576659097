import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { MyPersonaViewModel, MeViewModel } from 'external/api/types';
import { count } from 'common/linq';
import IconNotification from 'pages/app/navbar/icon-notification';
import IconMessages from 'pages/app/navbar/icon-messages';
import IconDashboard from 'pages/app/navbar/icon-dashboard';

import IconIntegrate from 'pages/app/navbar/icon-integrate';
import IconOffers from 'ui/icons/icon-offers';
import { RoleLogo } from 'ui/atoms/logo/logo';

import { ActiveIcons } from './active-icons';
import ProfileProgress from '../profile-progress';

import { FeatureFlag } from 'common';

import iconRequestSvg from './icon-requests.svg';
import { defaultUserLogo } from 'ui';

const MainNavLink = styled(NavLink).attrs((attrs) => ({
  className: `${attrs.className || ''} flex flex-col items-center relative`,
}))`
  height: 46px;

  &.active {
    color: var(--accent);
  }

  :hover {
    background: rgba(33, 156, 245, 0.6);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: white;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  }

  :hover > section {
    visibility: visible;
    color: white;
    height: 46px;
    background: rgba(33, 156, 245, 0.6);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    transform: translateX(60px);
  }
`;

const MainNavLinkTooltipSection = styled.section.attrs((attrs) => ({
  className: `${
    attrs.className || ''
  } invisible subsection absolute left-0 flex flex-row items-center px-4`,
}))`
  visibility: none;
  transform: translateX(55px);
  min-width: max-content;
`;

export function RoleMenu({
  user,
  role,
}: {
  user: MeViewModel;
  role: MyPersonaViewModel;
}) {
  const logoUrl = role.logoThumb || defaultUserLogo;
  const notificationsCount = count(
    user.notifications || [],
    (n) => n.for === role.id && !n.isRead
  );
  const messagesCount = role.unreadMessages;

  const steps: any[] = []; //useProfileSteps(role.id, role.type);

  return (
    <>
      <Link to="/profile" title="Edit profile">
        <RoleLogo type={role.type} logoUrl={logoUrl} background="#2f3b5c" />
      </Link>

      <div className="icons md:mt-8 md:mb-8">
        <NavLink
          to="/notifications"
          className="icon"
          title={
            notificationsCount > 0
              ? `${notificationsCount} unread notifications`
              : `Notifications`
          }
        >
          <IconNotification />
          {!!notificationsCount && (
            <span className="count">{notificationsCount}</span>
          )}
        </NavLink>
        <NavLink
          to="/messages"
          className="icon"
          title={
            messagesCount > 0 ? `${messagesCount} unread messages` : `Messages`
          }
        >
          <IconMessages />
          {!!messagesCount && <span className="count">{messagesCount}</span>}
        </NavLink>
      </div>

      <ActiveIcons className="hidden md:flex flex-col">
        {role.type === 'band' ? (
          <>
            <MainNavLink to="/" exact>
              <IconDashboard />
              <MainNavLinkTooltipSection>Dashboard</MainNavLinkTooltipSection>
            </MainNavLink>
            <MainNavLink to="/artist-requests">
              <IconRequests />
              <MainNavLinkTooltipSection>Requests</MainNavLinkTooltipSection>
            </MainNavLink>

            <FeatureFlag flag="venue-search">
              <MainNavLink to="/venue-search" exact>
                <IconSearch />
                <MainNavLinkTooltipSection>
                  Find venues
                </MainNavLinkTooltipSection>
              </MainNavLink>
            </FeatureFlag>
          </>
        ) : role.type === 'club' ? (
          <>
            <MainNavLink to="/" exact>
              <IconDashboard />
              <MainNavLinkTooltipSection>Dashboard</MainNavLinkTooltipSection>
            </MainNavLink>
            <MainNavLink to="/requests">
              <IconOffers />
              <MainNavLinkTooltipSection>Requests</MainNavLinkTooltipSection>
            </MainNavLink>
            <MainNavLink to="/club-integration">
              <IconIntegrate />
              <MainNavLinkTooltipSection>
                Integrate widget
              </MainNavLinkTooltipSection>
            </MainNavLink>
          </>
        ) : null}
      </ActiveIcons>

      <ProfileProgress steps={steps} />
    </>
  );
}

export function IconSearch(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-search"
      {...props}
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
  );
}

export function IconRequests(props: any) {
  return <img {...props} src={iconRequestSvg} alt="View all requests" />;
}
