import React from 'react';

export function IconMobileMenu(props: any) {
  return (
    <svg
      width="35"
      height="19"
      viewBox="0 0 35 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="0.953125"
        y1="1.12671"
        x2="34.9629"
        y2="1.12671"
        stroke="currentColor"
        strokeWidth="2"
      />
      <line
        x1="0.953125"
        y1="9.44434"
        x2="34.9629"
        y2="9.44434"
        stroke="currentColor"
        strokeWidth="2"
      />
      <line
        x1="0.953125"
        y1="17.7621"
        x2="34.9629"
        y2="17.7621"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
