import React from 'react';
import styled from 'styled-components/macro';

const Layout = styled.div`
  display: flex;

  > * {
    flex: 1;
    margin-right: 10px;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

export function GridLayout({ children }: { children: React.ReactNode }) {
  return <Layout>{children}</Layout>;
}
