import React from 'react';

import logoInline from 'ui/icons/logo_inline.svg';

export function WelcomeLogo() {
  return (
    <div className="flex flex-row justify-center">
      <img src={logoInline} alt="" />
    </div>
  );
}
