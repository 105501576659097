import { _post } from './common';

import { SendMessageDto } from './types';

export function sendMessage(
  senderId: number,
  messageGroupId: number,
  content: string
) {
  return _post<boolean>(`messaging/${senderId}/group/${messageGroupId}/send`, {
    messageGroupId,
    content,
    senderId,
  } as SendMessageDto);
}

export function messageGroupMarkRead(senderId: number, messageGroupId: number) {
  return _post<boolean>(
    `messaging/${senderId}/group/${messageGroupId}/markread`
  );
}
