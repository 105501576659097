import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { ButtonProps, Button } from '../button/button';

type Props = { to: string } & ButtonProps;

export function LinkButton({
  children,
  to,
  ...rest
}: React.PropsWithChildren<Props>) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(to);
  }, [history, to]);

  return (
    <Button {...rest} onClick={handleClick}>
      {children}
    </Button>
  );
}
