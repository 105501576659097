import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Page, Stack } from 'ui';
import IconBrokenLogo from 'ui/icons/icon-broken-logo';

const StyledPage = styled(Page)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;

  > svg {
    height: 100px;
  }

  > h2,
  > a {
    margin-top: 20px;
  }
`;

export default function NoMatch() {
  const location = useLocation();

  return (
    <StyledPage variant="content" title="Page not found">
      <Stack alignItems="center" space="large">
        <IconBrokenLogo />

        <h2 className="text-center">
          Ouch, the page {location && location.pathname} you requested doesn't
          exist.
        </h2>

        <Link to="/">Go to dashboard</Link>
      </Stack>
    </StyledPage>
  );
}
