import styled from 'styled-components/macro';
import cn from 'classnames';

export const FieldWrapper = styled.section`
  width: 100%;

  > input {
    margin-left: -2px;
  }
`;

export const FieldLabel = styled.span`
  color: var(--grey-blue);
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  margin-left: 0.5rem;
  letter-spacing: 0.05em;
`;

export const Input = styled.input.attrs((props) => ({
  ...props,
  className: cn('border-sm', props.className),
}))`
  height: 40px;
  border: none;
  width: 100%;
  background: #292a2d;
  color: white;
  padding: 6px 12px;
  font-size: 0.9em;
  line-height: 18px;
  letter-spacing: 0.05em;
  border-radius: 0.25rem;

  @media screen and (max-width: 767px) {
    height: 40px;
  }

  :disabled {
    opacity: 0.5;
  }

  :focus {
    outline: 1px solid var(--grey-blue);
  }

  ::placeholder {
    font-style: italic;
    color: var(--grey-blue) !important;
  }
`;
