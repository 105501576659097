import * as Sentry from '@sentry/browser';

class Logger {
  _initialized = false;

  init = () => {
    if (this._initialized) {
      return;
    }

    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
        release: 'gigpinfrontend@' + process.env.npm_package_version,
      });
    }

    this._initialized = true;
  };

  setUser = (user: any) => {
    Sentry.setUser(user);
  };

  clearUser = () => {
    Sentry.setUser(null);
  };
}

const logger = new Logger();

export { logger };
