import React from 'react';
import styled from 'styled-components/macro';

import { useUser } from 'context/user-context';

import { RoleDropdown as Sidebar } from '../sidebar/sidebar';
import Logo from '../logo';
import { StyledNav } from './StyledNav';
import { RoleMenu } from './role-menu';

import './navbar.css';
import { useHistory } from 'react-router-dom';

const StyledLogo = styled(Logo)`
  align-self: center;
  margin: 10px 15px 20px 15px;
  min-height: 42px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    margin-right: auto;
  }
`;

export default function Navbar() {
  const history = useHistory();
  const { isLoggedIn, userInfo, currentRole, setRole, getUserInfo } = useUser();
  if (!isLoggedIn || !userInfo) {
    return null;
  }

  function switchRole(roleId: number) {
    setRole(roleId);
    getUserInfo();
  }

  function goToHome() {
    history.push('/');
  }

  if (!currentRole) {
    return (
      <StyledNav className="header">
        <StyledLogo onClick={goToHome} />

        <Sidebar
          userInfo={userInfo}
          activeUserId={0}
          currentRole={currentRole}
          onSwitchRole={switchRole}
        />
      </StyledNav>
    );
  }

  return (
    <StyledNav className="header">
      <StyledLogo onClick={goToHome} />

      <RoleMenu user={userInfo} role={currentRole} />

      <Sidebar
        userInfo={userInfo}
        activeUserId={currentRole.id}
        currentRole={currentRole}
        onSwitchRole={switchRole}
      />
    </StyledNav>
  );
}
