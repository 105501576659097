import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise } from 'axios';

const rootURL = process.env.REACT_APP_API_URL || 'https://api-test.gigpin.com';

class ApiHolder {
  axios: AxiosInstance;

  constructor() {
    const config: AxiosRequestConfig = {
      baseURL: rootURL,
    };

    const token = localStorage.getItem('userToken');
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    this.axios = axios.create(config);
  }

  login(token: string) {
    localStorage.setItem('userToken', token);
    this.axios = axios.create({
      baseURL: rootURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  logout() {
    localStorage.removeItem('userToken');
    this.axios = axios.create({
      baseURL: rootURL,
    });
  }

  setRole(id: number) {
    this.axios.defaults.headers.common['TenantID'] = `${id}`;
  }
}

export const Api = new ApiHolder();

export function _get<T>(path: string) {
  return Api.axios.get<T>(`/api/${path}`.replace('//', '/'));
}

export function _post<T>(
  path: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return Api.axios.post<T>(`${rootURL}/api/${path}`, data, config);
}

export function _upload<T>(
  path: string,
  data: any,
  onUploadProgress?: (progress: number) => void
) {
  return Api.axios({
    data,
    method: 'post',
    url: `${rootURL}/api/${path}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      if (onUploadProgress) {
        onUploadProgress(percentCompleted);
      }
    },
  }) as AxiosPromise<T>;
}

export function _put<T>(path: string, data?: any) {
  return Api.axios.put<T>(`${rootURL}/api/${path}`, data);
}

export function _delete(path: string) {
  return Api.axios.delete(`${rootURL}/api/${path}`);
}
