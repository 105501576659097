import React, { useEffect, Suspense } from 'react';
import Intercom from 'react-intercom';

import { useUser } from './context/user-context';
import ErrorBoundary from './pages/app/error-boundary';
import Toasts from './pages/app/toasts/toasts';
import { MainContent } from './pages/app/main-content';
import Navbar from './pages/app/navbar/navbar';
import { Loading } from 'ui/Loading';

function IntercomWrapper() {
  const { userInfo } = useUser();

  const intercom = process.env.REACT_APP_INTERCOM;
  if (!intercom) {
    return null;
  }

  return (
    <Intercom
      appID="cphf1onu"
      {...{
        user_id: userInfo.email,
        email: userInfo.email,
        name: userInfo.name,
      }}
    />
  );
}

const AppContainer = ({ children }: React.PropsWithChildren<{}>) => {
  const { getUserInfo } = useUser();

  useEffect(() => {
    getUserInfo(); //.catch(() => {});
  }, [getUserInfo]);

  return (
    <ErrorBoundary>
      <Navbar />

      <Suspense fallback={<Loading delayed />}>
        <MainContent>{children}</MainContent>
      </Suspense>

      <Toasts />

      <IntercomWrapper />
    </ErrorBoundary>
  );
};

export default AppContainer;
