import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

const StyledContainer = styled.div`
  height: 100px;
  width: 6px;

  background: black;
  border-radius: 3px;
  margin: 0 auto;

  position: absolute;
  bottom: var(--space-medium);
`;

const Thumb = styled.div`
  transition: all 0.5s ease;
  background-color: var(--accent);
  width: 6px;
  border-radius: 3px;
`;

const PopupContainer = styled.div<{ open: boolean }>`
  opacity: ${(props) => (props.open ? '1' : '0')};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  background-color: rgba(27, 31, 46, 0.99);

  position: absolute;
  left: 60px;
  padding: 10px;
  top: 0;
  bottom: 0;
  width: 380px;

  z-index: 101;

  ${(props) =>
    props.open &&
    css`
      display: block;
      left: 70px;
    `}
`;

type Props = {
  steps: any[];
};

export default function ProfileProgress(props: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(false);
  };

  const toggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      window.addEventListener('click', handleClick);
    } else {
      window.removeEventListener('click', handleClick);
    }
  };

  if (!props.steps || props.steps.length === 0) {
    return null;
  }

  const width =
    props.steps.filter((step) => step.done).length / props.steps.length;
  if (width === 1) {
    return null;
  }

  return (
    <>
      <StyledContainer
        id="profile-progress-button"
        aria-haspopup="true"
        aria-expanded={isExpanded}
        onClick={toggle}
      >
        <Thumb style={{ height: `${width}%` }} />
      </StyledContainer>

      <PopupContainer
        aria-labelledby="profile-progress-button"
        open={isExpanded}
      >
        <h2>Next steps</h2>
      </PopupContainer>
    </>
  );
}
