import React from 'react';

import { useField } from 'react-final-form';

type Props = {
  name: string;
  showAlways?: boolean;
};
export default function FieldError(props: Props) {
  const { name, showAlways } = props;

  const field = useField(name, {
    subscription: { touched: true, error: true },
  });

  return (!!showAlways || field.meta.touched) && field.meta.error ? (
    <span className="field-error">{field.meta.error}</span>
  ) : null;
}
