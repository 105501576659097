import React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components/macro';

import FieldError from './field-error';
import { FieldWrapper, FieldLabel } from './common';

const InputContainer = styled.section`
  width: 98%;
  display: inline-flex;
  align-items: center;
  color: white;

  > input {
    margin-left: 0;
    margin-right: 15px;
  }
`;

const StyledChildren = styled.section`
  font-weight: 300;
  letter-spacing: 0.05em;
  font-size: 0.8rem;
  text-transform: none;
  display: flex;
  align-items: center;
`;

export function CheckboxFieldComponent2({
  className,
  label,
  input,
  meta,
  children,
  ...props
}: any) {
  return (
    <FieldWrapper className={className}>
      <label>
        {label && <FieldLabel>{label}</FieldLabel>}
        <InputContainer>
          <input {...props} {...input} type="checkbox" name={input.name} />
          <StyledChildren>{children}</StyledChildren>
        </InputContainer>
      </label>
      <FieldError name={input.name} />
    </FieldWrapper>
  );
}

export function CheckboxField({ name, ...props }) {
  return (
    <Field
      {...props}
      type="checkbox"
      name={name}
      component={CheckboxFieldComponent2}
    />
  );
}
