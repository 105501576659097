import React from 'react';
import { Link } from 'react-router-dom';

export const translations = {
  error_login_userdoesntexist:
    "User with this email address doesn't exist. Would you like to sign up instead?",
  error_facebookidalreadyused:
    'This Facebook page is already linked to an account.',
  error_invalidband: 'You must be a band to be able to book venue',
  error_bandnotverified: (
    <>
      You must verify your band before sending requests.
      <Link to="/verify-account">Verify account</Link>
    </>
  ),
  error_norider: (
    <>
      To book a venue you need to have a valid rider.
      <Link to="/profile/rider">Create rider here</Link>
    </>
  ),
  error_nopins: (
    <>
      You don't have any pins! <Link to="/profile">Get pins</Link>
    </>
  ),
  error_invalidclub: "This club doesn't exist",
  error_clubnotverified: 'This club is not yet verified',
  error_invalidpage: "This club doesn't exist",
};
