import React from 'react';

import { useNotifications } from 'context/notifications-context';

import './toasts.css';

export type Alert = {
  id: number;
  text: string;
  className: string;
  dateAdded?: any;
};

class Notification extends React.Component<{
  notification: Alert;
  onClose: Function;
}> {
  render() {
    const {
      notification: { className, text, dateAdded },
    } = this.props;
    return (
      <div className={'alert ' + className} role="alert">
        <button
          type="button"
          className="close"
          onClick={() => this.props.onClose()}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <p>
          {text} {!!dateAdded && <span>{dateAdded}</span>}
        </p>
      </div>
    );
  }
}

export default function Toasts() {
  const { notifications, close } = useNotifications();

  return (
    <div className="global-notifications">
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          notification={{
            ...notification,
            className: `alert-${notification.type}`,
          }}
          onClose={() => close(notification.id)}
        />
      ))}
    </div>
  );
}
