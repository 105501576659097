import React from 'react';

import { TokenProvider } from './token-context';
import { UserProvider } from './user-context';
import { NotificationsProvider } from './notifications-context';

export default function Contexts({ children }: React.PropsWithChildren<{}>) {
  return (
    <TokenProvider>
      <UserProvider>
        <NotificationsProvider>{children}</NotificationsProvider>
      </UserProvider>
    </TokenProvider>
  );
}
