import React, { FunctionComponent } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import 'styled-components/macro';

import { FieldWrapper, Input, FieldLabel } from './common';
import FieldError from './field-error';

export type InputFieldComponentProps = {
  type?: 'text' | 'number';
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  pattern?: string;
};

const InputFieldComponent: FunctionComponent<
  InputFieldComponentProps & FieldRenderProps<string | number, HTMLInputElement>
> = ({ className, label, input, meta, ...rest }) => {
  return (
    <FieldWrapper className={className}>
      <label aria-label={!label ? `${input.name} input field` : undefined}>
        {!!label && <FieldLabel>{label}</FieldLabel>}
        <Input
          {...rest}
          {...input}
          {...meta}
          name={input.name}
          css={`
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            & {
              -moz-appearance: textfield; /* Firefox */
            }
          `}
        />
      </label>
      <FieldError name={input.name} />
    </FieldWrapper>
  );
};

export function InputField({
  name,
  ...props
}: { name: string } & InputFieldComponentProps) {
  return (
    <Field {...props} name={name} component={InputFieldComponent as any} />
  );
}

export function NumberField({
  name,
  ...props
}: { name: string } & Omit<InputFieldComponentProps, 'type'>) {
  return (
    <Field
      {...props}
      name={name}
      component={InputFieldComponent as any}
      parse={(x) => {
        // allow deleting number 0 in the input
        if (x === '') {
          return '';
        }
        return Number(x);
      }}
      type="number"
    />
  );
}
