import styled, { css } from 'styled-components/macro';
import { Property } from 'csstype';

import { SpacingType } from 'ui/atoms/types';

type StackProps = {
  alignItems?: Property.AlignItems;
  space?: SpacingType;
};

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;

  ${(props) => css`
    ${props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}

    > * {
      margin-bottom: var(${`--space-${props.space || 'medium'}`});
    }
    > *:last-child {
      margin-bottom: 0;
    }
  `}
`;
