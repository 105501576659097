import { RegisterUserCommand } from './types';
import { _post, _get } from './common';

export const register = (
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  confirmPassword: string,
  agreeNewsletter: boolean
) => {
  return _post(`user/register`, {
    email,
    firstName,
    lastName,
    password,
    confirmPassword,
    agreeNewsletter,
  } as RegisterUserCommand);
};

export const fetchInvite = (invitationId: string) => {
  return _get(`invite/${invitationId}`);
};

export const acceptInvite = (invitationId: string) => {
  return _post(`invite/${invitationId}/accept`);
};

export const loginWithFacebookClickHandler = () => {
  const scope = 'email';
  window.location.href = `https://www.facebook.com/v3.3/dialog/oauth?&response_type=token&display=popup&client_id=${process.env.REACT_APP_FACEBOOK_APPID}&display=popup&redirect_uri=${window.location.origin}/facebook-auth&scope=${scope}`;
};

export function linkWithFacebookClickHandler() {
  // const scope = 'email%2Cpages_show_list%2Cpublic_profile';
  const scope = 'email%2Cpublic_profile';
  window.location.href = `https://www.facebook.com/v3.3/dialog/oauth?&response_type=token&display=popup&client_id=${process.env.REACT_APP_FACEBOOK_APPID}&display=popup&redirect_uri=${window.location.origin}/settings/link-facebook&scope=${scope}`;
}

export function getPermissionsForPages(returnUrl: string) {
  const scope = 'email%2Cpages_show_list%2Cpublic_profile';
  // const scope = 'email%2Cpublic_profile';
  window.location.href = `https://www.facebook.com/v3.3/dialog/oauth?&response_type=token&display=popup&client_id=${process.env.REACT_APP_FACEBOOK_APPID}&display=popup&redirect_uri=${window.location.origin}${returnUrl}&scope=${scope}`;
}
