import styled, { css } from 'styled-components/macro';
import { Property } from 'csstype';

import { SpacingType } from '../types';

type InlineProps = {
  space?: SpacingType;
  alignItems?: Property.AlignItems;
};

export const Inline = styled.section<InlineProps>`
  display: flex;
  flex-direction: row;

  ${(props) => css`
    ${props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}

    > * {
      margin-right: var(${`--space-${props.space || 'medium'}`});
    }
    > *:last-child {
      margin-right: 0;
    }
  `}
`;
