import { _post, _put, _get, _delete } from './common';
import {
  UploadedImage,
  CreateBandCommand,
  CreateBandFromFacebookCommand,
  ConnectBandToFacebookCommand,
  BandDashboardResult,
  UpdateBandProfileCommand,
  GetBandProfileQueryResult,
} from './types';

export function createBand(data: CreateBandCommand) {
  return _post<number>('bands', data);
}

export function createBandFromFacebook(data: CreateBandFromFacebookCommand) {
  return _post<number>('bands/facebook', data);
}

export function getBandProfile(bandId: number) {
  return _get<GetBandProfileQueryResult>(`bands/${bandId}/profile`);
}

export function saveBandInfo(bandInfo: UpdateBandProfileCommand) {
  return _put(`bands/${bandInfo.bandId}/profile`, bandInfo);
}

export function getBandDashboard(bandId: number) {
  return _get<BandDashboardResult>(`banddashboard/${bandId}`);
}

export function uploadGalleryImage(
  ownerId: number,
  image: any,
  onProgress: (progress: number) => void
) {
  const data = new FormData();
  data.append('file', image);

  return _post<UploadedImage>(`images/gallery/${ownerId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: e => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      if (onProgress) {
        onProgress(percentCompleted);
      }
    },
  });
}

export function deleteBand(bandId: number) {
  return _delete(`bands/${bandId}`);
}

export function connectBandToFacebook(
  bandId: number,
  facebookId: string,
  link: string,
  fanCount: number
) {
  const data: ConnectBandToFacebookCommand = {
    bandId,
    facebookId,
    link,
    fanCount,
  };
  return _put<boolean>(`bands/${bandId}/facebook`, data);
}

export function disconnectBandFromFacebook(bandId: number) {
  return _delete(`bands/${bandId}/facebook`);
}
