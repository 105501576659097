export function any<T>(
  input: T[],
  predicate: (element: T) => boolean
): boolean {
  if (!predicate) return input.length !== 0;

  for (let i = 0; i < input.length; ++i) {
    if (predicate(input[i])) return true;
  }
  return false;
}

export function all<T>(
  input: T[],
  predicate: (element: T) => boolean
): boolean {
  if (!predicate) return false;

  for (let i = 0; i < input.length; ++i) {
    if (!predicate(input[i])) return false;
  }
  return true;
}

export function firstOrDefault<T>(
  input: T[],
  predicate: (element: T) => boolean
): T | null {
  if (!predicate) {
    if (input.length === 0) return null;
    return input[0];
  }

  for (let i = 0; i < input.length; ++i) {
    if (predicate(input[i])) return input[i];
  }

  return null;
}

export function count<T>(
  input: T[],
  predicate: (element: T) => boolean
): number {
  if (!predicate) return input.length;
  let total = 0;

  for (let i = 0; i < input.length; ++i) {
    if (predicate(input[i])) total++;
  }

  return total;
}

export function orderBy<T>(input: T[], sortComparer: (l: T, r: T) => number) {
  const copy = [...input];
  copy.sort(sortComparer);
  return copy;
}
