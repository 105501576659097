import { AxiosError } from 'axios';

import { translateError } from './translateError';

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
export const delayError = (ms: number) =>
  new Promise((_, reject) => setTimeout(reject, ms));

export function toEmbedVimeoUrl(url: string) {
  const regex = new RegExp(/https?:\/\/(www\.)?vimeo.com\/(\d*)/gim);
  const result = regex.exec(url);
  if (!!result) {
    const id = result[result.length - 1];

    return `https://player.vimeo.com/video/${id}?color=f05050&title=0&byline=0&portrait=0`;
  }
  return '';
}

export const toEmbedVideo = (url: string) => {
  const id = parseYoutubeId(url);
  const result = `https://www.youtube.com/embed/${id}?showinfo=0`;
  return result;
};

export function parseYoutubeId(url: string) {
  const regex = new RegExp(
    /https?:\/\/(youtu\.be|(www)?\.youtube\.com)\/(watch\?v=)?(?<id>[\w-]*)\??/gim
  );
  const result = regex.exec(url);

  if (!!result) {
    return result[result.length - 1] || '';
  }

  return '';
}

export const formatError = (e: any) => {
  return 'An error occurred';
};

const genericErrorMessage: React.ReactNode = 'An error ocurred';

export function formatApiError(error: AxiosError): React.ReactNode {
  if (!error) {
    return genericErrorMessage;
  }

  if (!error.response) {
    return (
      error.message ||
      'We have trouble reaching GigPin at the moment. Please try in a few minutes.'
    );
  }

  if (error.response && error.response.data) {
    if (error.response.status !== 400) {
      return genericErrorMessage;
    }

    let message: React.ReactNode[] = [];
    const modelState = error.response.data.errors
      ? error.response.data.errors
      : error.response.data;

    for (const i in modelState) {
      if (modelState.hasOwnProperty(i)) {
        // message = [message, ...modelState[i].map(translateError)];
        modelState[i].map(translateError).forEach((e) => {
          if (!!e) {
            message.push(e);
          }
        });
      }
    }

    return message;
  }

  return genericErrorMessage;
}

export interface ValidationError {
  field: string;
  error: string;
}

// TODO: remove?
export const getErrors = (
  error: { message: string } | any,
  genericErrorName = 'error'
) => {
  const errors: {} = {};
  let genericErrors: string[] = [];

  if (error) {
    if (error.message) genericErrors.push(error.message);

    for (const key in error) {
      if (error.hasOwnProperty(key)) {
        const element = error[key];
        if (key === '') {
          genericErrors = [...genericErrors, element];
        } else {
          const name = toCamelCase(last(key.split('.')));
          errors[name] = element.join(' ');
        }
      }
    }

    if (genericErrors.length > 0) {
      errors[genericErrorName] = genericErrors.join(' ');
    }
  }

  return errors;
};

export function last<T>(input: T[]) {
  return input[input.length - 1];
}

export function toCamelCase(input: string): string {
  if (!input) {
    return input;
  }

  if (input.length === 1) {
    return input[0].toLowerCase();
  }

  return input[0].toLowerCase() + input.substr(1);
}
