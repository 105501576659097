import moment from 'moment';
import {
  startOfDay,
  formatDistanceToNow,
  isDate,
  parseISO,
  format,
} from 'date-fns';

/**
 * Returns a < b
 *
 * @export
 * @param {*} a
 * @param {*} b
 * @returns
 */
export function isBeforeDay(a: any, b: any) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

/**
 * Returns a > b
 *
 * @export
 * @param {*} a
 * @param {*} b
 * @returns
 */
export function isAfterDay(_a: any, _b: any) {
  const a = moment(_a);
  const b = moment(_b);

  return !isBeforeDay(a, b) && !isSameDay(a, b);
}

export function isSameDay(_a: any, _b: any) {
  //if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  const a = moment(_a);
  const b = moment(_b);

  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  );
}

export function getToday() {
  return startOfDay(new Date());
}

export function fromNow(dateRaw: any) {
  const date = isDate(dateRaw) ? dateRaw : parseISO(dateRaw);
  return formatDistanceToNow(date);
}

export function formatDate(dateRaw: any, fmt: string) {
  const date = isDate(dateRaw) ? dateRaw : parseISO(dateRaw);
  return format(date, fmt);
}

export function formatISODate(dateRaw: any) {
  const date = isDate(dateRaw) ? dateRaw : parseISO(dateRaw);
  return format(startOfDay(date), 'yyyy-MM-dd');
}
