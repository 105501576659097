import React, { useState } from 'react';
import { Api } from 'external/api/common';
import { logger } from 'utils/logging';

type TokenContextProps = {
  token: string;
  login: (token: string) => any;
  logout: Function;
};

const TokenContext = React.createContext<TokenContextProps>({
  token: '',
} as any);

const initialToken = localStorage.getItem('userToken') || '';

function TokenProvider({ children }: React.PropsWithChildren<{}>) {
  const [token, setToken] = useState(initialToken);

  const value = {
    token,
    login: (token: string) => {
      localStorage.setItem('userToken', token);

      // TODO: refactor maybe
      Api.login(token);

      setToken(token);
    },
    logout: () => {
      localStorage.removeItem('userToken');

      setToken('');

      logger.clearUser();
    },
  };

  return (
    <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
  );
}

function useToken() {
  return React.useContext(TokenContext);
}

export { TokenProvider, useToken };
