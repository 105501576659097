import { _post } from './common';

import {
  LinkWithFacebookCommand,
  RegisterWithFacebookCommand,
  LoginWithFacebookCommand,
  GetFacebookUserInfoQuery,
  FacebookUserInfoResult,
  JwtTokenDto,
} from './types';

export const USER_NOT_REGISTERED = 'USER_NOT_REGISTERED';
export const USER_NOT_LINKED = 'USER_NOT_LINKED';
export const FACEBOOK_SESSION_EXPIRED = 'FACEBOOK_SESSION_EXPIRED';

export function loginViaFacebook(request: LoginWithFacebookCommand) {
  return _post<JwtTokenDto>(`user/login-facebook`, request);
}

export function getFacebookUserInfo(request: GetFacebookUserInfoQuery) {
  return _post<FacebookUserInfoResult>(`user/get-facebook-info`, request);
}

export function registerViaFacebook(request: RegisterWithFacebookCommand) {
  return _post<JwtTokenDto>(`user/register-facebook`, request);
}

export function linkWithFacebook(request: LinkWithFacebookCommand) {
  return _post(`user/link-facebook`, request);
}

export function unlinkFacebook() {
  return _post(`user/unlink-facebook`);
}
