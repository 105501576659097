import React from 'react';

export default function IconBrokenLogo() {
  return (
    <svg
      width="178"
      height="204"
      viewBox="0 0 357 408"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.365 33.5554C171.738 33.5554 165.262 34.2053 158.982 35.3995L143.416 3.21145C143.82 3.15886 144.223 3.10577 144.627 3.05268L144.627 3.05261L144.629 3.05233C146.142 2.85325 147.655 2.65417 149.187 2.48104C158.666 1.41181 168.472 0.828589 178.367 0.703613C188.254 0.828589 198.063 1.41181 207.542 2.48104C257.796 8.14658 301.335 23.7018 322.303 58.906C324.747 63.0079 326.738 67.3404 328.354 71.8367L279.506 116.447C270.094 69.1817 228.396 33.5554 178.365 33.5554ZM85.8347 156.225C95.8105 119.058 126.021 90.1858 163.939 82.1429L148.308 49.8132C101.445 56.2009 61.3506 71.9033 41.4935 105.233C35.2336 115.742 31.8481 127.712 30.5762 140.295L85.8347 156.225ZM304.471 134.197C304.471 144.756 302.877 154.942 299.928 164.535L340.764 176.308C353.67 145.142 362.768 104.097 352.059 71.4785L302.902 116.37C303.913 122.163 304.471 128.114 304.471 134.197ZM277.551 205.627C263.987 246.755 225.276 276.446 179.604 276.446C170.273 276.446 161.238 275.185 152.643 272.863L98.6562 322.165C135.76 375.971 169.539 404.165 179.61 407.848C194.801 402.291 264.001 340.956 317.138 219.896C317.438 219.212 317.734 218.516 318.032 217.819L318.229 217.355L277.551 205.627ZM52.798 183.959C52.798 230.736 83.9418 270.211 126.617 282.845L73.8022 331.077C55.267 303.977 35.9625 270.542 18.4076 230.547C7.37925 205.427 -1.54953 172.825 0.869433 143.334L55.8169 159.175C53.8561 167.115 52.798 175.414 52.798 183.959Z"
        fill="#219CF5"
      />
      <path
        d="M233.219 175.53C233.219 152.054 214.187 133.019 190.708 133.019C170.679 133.019 153.927 146.894 149.428 165.54L230.984 189.052C232.411 184.8 233.219 180.262 233.219 175.53Z"
        fill="white"
      />
      <path
        d="M179.071 186.958L151.324 214.705C158.842 221.421 168.729 225.539 179.602 225.539C197.609 225.539 212.965 214.325 219.158 198.517L179.071 186.958Z"
        fill="white"
      />
      <path
        d="M139.699 205.907C148.867 196.74 158.032 187.575 167.199 178.407L126.752 166.746C126.263 169.326 125.982 171.978 125.982 174.7C125.982 187.053 131.284 198.139 139.699 205.907Z"
        fill="white"
      />
    </svg>
  );
}
