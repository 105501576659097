import React from 'react';

export default function IconNewRole(props: any) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79403 18.8859C0.886353 14.9782 0.886353 8.64262 4.79403 4.73495C8.70171 0.82727 15.0373 0.827271 18.945 4.73495C22.8527 8.64262 22.8527 14.9782 18.945 18.8859C15.0373 22.7936 8.70171 22.7936 4.79403 18.8859ZM4.08693 4.02785C-0.211268 8.32604 -0.211268 15.2948 4.08693 19.593C8.38513 23.8912 15.3539 23.8912 19.6521 19.593C23.9503 15.2948 23.9503 8.32604 19.6521 4.02785C15.3539 -0.27035 8.38513 -0.27035 4.08693 4.02785ZM12.3639 5.18817V11.3211H18.4707V12.3211H12.3639V18.4287H11.3639V12.3211H5.2302V11.3211H11.3639V5.18817H12.3639Z"
      />
    </svg>
  );
}
