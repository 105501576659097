import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FacebookPagesData, FacebookAccountDto } from 'external/api/types';
import { connectBandToFacebook } from 'external/api/bands';
import { connectClubToFacebook } from 'external/api/clubs';
import { useQuery } from 'HelperFunctions/useQuery';
import { useUser } from 'context/user-context';
import Form from 'ui/forms/form';
import { getFacebookInfoFromRedirectUrl } from 'common/facebook-helpers';
import { ExternalLink } from 'ui/external-link';
import { LoadingOverlay } from 'ui/Loading';
import { Page } from 'ui/page';
import { Button } from 'ui/atoms/button/button';

import FacebookPages from './profile-connect-facebook/facebook-pages';
import ErrorPage from './error-page';

export default function ProfileConnectFacebookPage() {
  const history = useHistory();
  const { accessToken } = getFacebookInfoFromRedirectUrl();

  const [selectedPage, setSelectedPage] = useState<
    FacebookAccountDto | undefined
  >();
  const { result: pages, isLoading, error: errorLoadingPages } = useQuery<
    FacebookPagesData
  >(`/user/get-facebook-pages?access_token=${accessToken}`);
  const { currentRole } = useUser();

  if (errorLoadingPages) {
    return (
      <ErrorPage>
        We cannot connect to Facebook at this moment, please try again later.
      </ErrorPage>
    );
  }
  if (isLoading || !pages || !currentRole) {
    return <LoadingOverlay />;
  }

  function selectPage(page: FacebookAccountDto) {
    setSelectedPage(page);
  }

  async function connectFacebookPageToPersona() {
    if (!!currentRole && !!selectedPage) {
      if (currentRole.type === 'band') {
        await connectBandToFacebook(
          currentRole.id,
          selectedPage.id,
          selectedPage.link,
          selectedPage.fanCount
        );
      } else if (currentRole.type === 'club') {
        await connectClubToFacebook(
          currentRole.id,
          selectedPage.id,
          selectedPage.link,
          selectedPage.fanCount
        );
      }
      history.push('/profile/information');
    }

    return Promise.reject();
  }

  return (
    <Page variant="content" title="Connect GigPin to Facebook">
      <Page.Title>Connect GigPin to Facebook</Page.Title>

      {selectedPage ? (
        <Form
          onSubmit={connectFacebookPageToPersona}
          className="flex flex-col space-y-medium items-start"
        >
          <p>
            Are you sure you want to link Facebook page{' '}
            <ExternalLink href={selectedPage.link}>
              {selectedPage.name}
            </ExternalLink>{' '}
            to GigPin profile {currentRole.name}?
          </p>
          <Button variant="primary">Connect</Button>
        </Form>
      ) : (
        <FacebookPages pages={pages} onClick={selectPage} />
      )}
    </Page>
  );
}
