import { _get, _post, _put, _delete } from './common';
import {
  GetClubDashboardResult,
  ClubProfileDto,
  CreateClubCommand,
  UpdateClubProfileCommand,
  CreateClubFromFacebookCommand,
  ConnectClubToFacebookCommand,
} from './types';

export function getClubDashboard(id: number) {
  return _get<GetClubDashboardResult>(`clubdashboard/${id}`);
}

export function getClubProfile(id: number) {
  return _get<ClubProfileDto>(`clubs/${id}/profile`);
}

export function createClub(data: CreateClubCommand) {
  return _post<number>(`clubs`, data);
}

export function createClubFromFacebook(data: CreateClubFromFacebookCommand) {
  return _post<number>(`clubs/facebook`, data);
}

export function updateClubProfile(
  clubId: number,
  data: UpdateClubProfileCommand
) {
  return _put<ClubProfileDto>(`clubs/${clubId}/profile`, data);
}

export function deleteClub(clubId: number) {
  return _delete(`clubs/${clubId}`);
}

export function connectClubToFacebook(
  clubId: number,
  facebookId: string,
  link: string,
  fanCount: number
) {
  const data: ConnectClubToFacebookCommand = {
    clubId,
    facebookId,
    link,
    fanCount,
  };
  return _put<boolean>(`clubs/${clubId}/facebook`, data);
}

export function disconnectClubFromFacebook(clubId: number) {
  return _delete(`clubs/${clubId}/facebook`);
}
