import styled from 'styled-components/macro';

export const MainContent = styled.main`
  margin-left: 70px;
  width: calc(100% - 70px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding-top: 0px;
  padding-bottom: 50px;

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 70px;
    width: 100%;
  }
`;
