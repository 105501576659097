//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface CanAccessMusicPersona {}
export interface LocationDto {
  city: string;
  countryName: string;
  countryCode: string;
  address: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  allowAccess: boolean;
}
export interface ArtistRequestPaymentInfo {
  paymentType: PaymentType;
  amount?: number;
  currency?: number;
  message: string;
}
export interface PersonaDto {
  id: number;
  name: string;
  thumbnail: string;
  location: LocationDto;
  isVerified: boolean;
  isImported: boolean;
}
export interface ArtistPersonaDto {
  id: number;
  name: string;
  thumbnail: string;
  location: LocationDto;
  isVerified: boolean;
  isImported: boolean;
  genres: number[];
}
export interface PagedResponse<T> {
  total: number;
  page: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  items: T[];
}
export interface ToggleVerifiedCommand {
  id: number;
}
export interface UploadedImage {
  id: number;
  uri: string;
  thumbUri: string;
}
export interface AddMessageFromSendgridParseCommand {
  requestId: string;
  text: string;
  from: string;
}
export interface SearchVenueResult {
  items: SearchVenueResultItem[];
}
export interface SearchVenuesQuery {}
export interface SearchVenueResultItem {
  hasRequestDefinition: boolean;
  club: PersonaDto;
}
export interface VenueSearchResultPin {
  id?: number;
  currentStatus: PinStatusType;
  modifiedOn: string;
  requestId: string;
  date: string;
  tourId?: number;
  isDeleted: boolean;
}
export interface VenueSearchResultData {
  pins: VenueSearchResultPin[];
}
export interface VenueSearchResult {
  id: number;
  name: string;
  latitude?: number;
  longitude?: number;
  data: VenueSearchResultData;
  clubId: number;
  isClub: boolean;
}
export interface CountryViewModel {
  name: string;
  code: string;
}
export interface AdminVenueSearchResult {
  id: number;
  name: string;
  latitude?: number;
  longitude?: number;
  address: string;
  contactName: string;
  country: CountryViewModel;
  description: string;
  detail: string;
  distance?: number;
  genre: GenreViewModel;
  googlePlaceId: string;
  phoneNumber: string;
  websiteUrl: string;
  venueType: VenueType;
  data: any;
  clubId: number;
  isClub: boolean;
  isValid: boolean;
}
export interface DeleteRiderCommand {
  bandId: number;
}
export interface RiderForBand {
  id: number;
  data: string;
  bandId: number;
  files: UploadedFile[];
}
export interface GetRiderQuery {
  bandId: number;
}
export interface RiderDataFile {
  url: string;
  type: string;
  usageType: string;
}
export interface RiderDataMusician {
  name: string;
  position: string;
}
export interface RiderDataTechnical {
  files: RiderDataFile[];
  musicians: RiderDataMusician[];
  ownBackline: boolean;
  ownSoundEngineer: boolean;
  extra: string;
}
export interface RiderDataMerchandise {
  hasMerchandise: boolean;
}
export interface RiderDataOrganization {
  setupTime: number;
  soundcheckTime: number;
  setDuration: number;
  breaks: string;
  extra: string;
}
export interface RiderDataLogistics {
  needParkingSpace: boolean;
  needHelpUnloading: boolean;
}
export interface RiderDataHospitality {
  peopleTotal: number;
  accommodation: string;
  drinkTickets: number;
  meals: number;
  extra: string;
  guestList: number;
}
export interface RiderData {
  technical: RiderDataTechnical;
  merchandise: RiderDataMerchandise;
  organization: RiderDataOrganization;
  logistics: RiderDataLogistics;
  hospitality: RiderDataHospitality;
}
export interface SendTestRiderCommand {
  bandId: number;
  email: string;
}
export interface UpdateRiderCommand {
  bandId: number;
  data: string;
}
export interface ClubRequestDefinition {
  dates: Definition;
  generalInfo: Definition;
  note: string;
}
export interface GetClubRequestDefinitionResult {
  hasDefinition: boolean;
  definition: string;
}
export interface GetClubRequestDefinitionQuery {
  clubId: number;
}
export interface UpdateClubRequestDefinitionCommand {
  clubId: number;
  data: string;
}
export interface Definition {
  show: boolean;
}
export interface AcceptArtistRequestCommand {
  artistRequestId: number;
  date: string;
}
export interface ArtistRequestNotificationData {
  artistRequestId: number;
  artistId: number;
  artistName: string;
  clubId: number;
  clubName: string;
  dates: string;
}
export interface CancelArtistRequestCommand {
  requestId: number;
}
export interface CreateArtistRequestCommand {
  artistId: number;
  clubId: number;
}
export interface CreateNotificationsForBookingFlow {}
export interface DeclineArtistRequestData {
  message: string;
}
export interface DeclineArtistRequestCommand {
  requestId: number;
  data: string;
}
export interface SendMessageAfterArtistRequestDeclined {}
export interface DeleteArtistRequestCommand {
  requestId: number;
}
export interface GetArtistRequestHistoryResult {
  eventType: ArtistRequestHistoryEventType;
  sentOn: string;
}
export interface GetArtistRequestResult {
  id: number;
  state: ArtistRequestState;
  requestData: string;
  messageGroupId?: number;
  hasUnread: boolean;
  club: PersonaDto;
  dates: string;
  paymentInfo: ArtistRequestPaymentInfo;
  history: GetArtistRequestHistoryResult[];
}
export interface GetArtistRequestQuery {
  artistId: number;
  requestId: number;
}
export interface ArtistRequestDto {
  id: number;
  state: ArtistRequestState;
  hasUnread: boolean;
  messageGroupId?: number;
  club: PersonaDto;
  dates: string;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface GetArtistRequestsResult {
  requests: ArtistRequestDto[];
}
export interface GetArtistRequestsQuery {
  artistId: number;
}
export interface ArtistRequestDefinitionGeneralInfo {
  id: number;
  name: string;
  email: string;
  description: string;
  thumbnail: string;
  genres: number[];
  location: string;
  images: UploadedImage[];
  links: any;
}
export interface ArtistRequestDefinitionPaymentInfo {
  paymentType: PaymentType;
  amount: number;
  currency: number;
  message: string;
}
export interface ArtistRequestDefinitionDates {
  dates: string[];
  isOpenDate: boolean;
  message: string;
}
export interface ArtistRequestDefinitionPromotion {
  drawSize?: number;
  venueDoesPromotion?: boolean;
  bandDoesSocial?: boolean;
  additional: string;
}
export interface ArtistRequestDefinition {
  dates: ArtistRequestDefinitionDates;
  message: string;
  generalInfo: ArtistRequestDefinitionGeneralInfo;
  paymentInfo: ArtistRequestDefinitionPaymentInfo;
  promotion: ArtistRequestDefinitionPromotion;
}
export interface SaveArtistRequestCommand {
  artistId: number;
  requestId: number;
  data: string;
}
export interface CreateMessageAfterArtistRequestSent {}
export interface SendArtistRequestCommand {
  requestId: number;
}
export interface SendRequestChangesCommand {
  artistId: number;
  requestId: number;
  data: string;
}
export interface SendMailsForBookingFlow {}
export interface PublicProfileMember {
  userName: string;
  role: MemberRoleType;
  isAdmin: boolean;
}
export interface PublicProfile {
  profileType: ProfileType;
  id: number;
  name: string;
  logo: string;
  isVerified: boolean;
  meta: string;
  musicians: string;
  info: string;
  location: LocationDto;
  canEdit: boolean;
  facebookFanCount: number;
}
export interface BandPublicProfile extends PublicProfile {
  members: PublicProfileMember[];
  bio: string;
  genres: GenreViewModel[];
  images: UploadedImage[];
}
export interface ClubPublicProfile extends PublicProfile {
  maxCapacity: number;
  workingDays: ClubWorkingDays;
  firstDayOfWeek: DayOfWeek;
  isValid: boolean;
  hasRequestDefinition: boolean;
  images: UploadedImage[];
}
export interface GetPublicProfileQuery {
  id: number;
}
export enum ProfileType {
  Unknown = 0,
  Band = 1,
  Club = 2,
}
export interface NotificationForUser {
  id: number;
  createdOn: string;
  type: string;
  data: string;
  isRead: boolean;
  for: number;
}
export interface GetUserNotificationsQuery {
  id: number;
  pageNo: number;
  count: number;
}
export interface MarkUserNotificationReadCommand {
  notificationId: number;
}
export interface MarkUserNotificationUnreadCommand {
  notificationId: number;
}
export interface NotificationQueueItem {
  notificationId: number;
  pinId: number;
  type: NotificationQueueItemTargetType;
  userId: string;
  email: string;
}
export enum NotificationQueueItemTargetType {
  Club = 0,
  Band = 1,
  Venue = 2,
}
export interface CreatedMessage {
  message: any;
  statuses: any[];
}
export interface GroupMessages {
  artistRequestId: number;
  members: GroupMessageMember[];
  items: RecentMessageGroupResult[];
  events: MessageGroupEvent[];
}
export interface MessageGroupEvent {
  date: string;
  type: ArtistRequestHistoryEventType;
}
export interface GroupMessageMember {
  id: number;
  name: string;
  logo: string;
  logoThumb: string;
  type: string;
}
export interface GetMessageGroupQuery {
  personaId: number;
  groupId: number;
}
export interface GetMessageGroupsResult {
  items: GetMessageGroupsGroup[];
}
export interface GetMessageGroupsGroup {
  content: string;
  id: number;
  name: string;
  city: string;
  country: string;
  logo: string;
  logoThumb: string;
  roleType: string;
  isUnread: boolean;
  artistRequestId: number;
  sentOn: string;
  dates: string;
  paymentType: PaymentType;
  amount: number;
  currency: number;
  artistRequestState: ArtistRequestState;
}
export interface GetMessageGroupsQuery {
  id: number;
}
export interface MarkMessageGroupAsReadCommand {
  ownerId: number;
  messageGroupId: number;
}
export interface MessageQueueItem {
  messageId: number;
  senderId: number;
  recipientId: number;
  userId: string;
}
export interface QueueMessageDelegate {}
export interface PinForMessaging {
  id: number;
  bandId?: number;
  clubId?: number;
}
export interface PinMessageSentViewModel {
  id: number;
}
export interface SendMessageCommand {
  messageGroupId: number;
  message: string;
  senderId: number;
}
export interface SendMessageModel {
  pinId: number;
  senderId: number;
  recipientId: number;
  content: string;
}
export interface UnreadMessageGroups {
  total: number;
  unreadGroupsFor: { [key: number]: number };
}
export interface MyPersonaViewModel {
  id: number;
  type: string;
  logo: string;
  logoThumb: string;
  name: string;
  role: string;
  unreadMessages: number;
  isVerified: boolean;
  hasRider: boolean;
}
export interface MeViewModel {
  name: string;
  email: string;
  avatarUrl: string;
  memberOf: MyPersonaViewModel[];
  notifications: NotificationForUser[];
  isConfirmed: boolean;
  unread: number;
  hasFacebook: boolean;
}
export interface GetMeQuery {}
export interface GetSettingsResult {
  firstName: string;
  lastName: string;
  avatarUrl: string;
  hasPassword: boolean;
  hasFacebook: boolean;
}
export interface GetSettingsQuery {}
export interface UpdateSettingsCommand {
  firstName: string;
  lastName: string;
}
export interface SendInviteCommand {
  roleId: number;
  email: string;
}
export interface GenreViewModel {
  id: number;
  name: string;
}
export interface GetGenresQuery {}
export interface AttachFileToMusicPersonaCommand {
  ownerId: number;
  name: string;
  uri: string;
  containerName: string;
  reference: string;
  fileType: FileType;
}
export interface AttachGalleryImageToMusicPersonaCommand {
  ownerId: number;
  uri: string;
  thumbnail: string;
  containerName: string;
  reference: string;
}
export interface DeleteFileCommand {
  id: number;
}
export interface DeleteGalleryImageCommand {
  id: number;
}
export interface FileInfo {
  name: string;
  type: FileType;
  uri: string;
}
export interface GetFilesResult {
  files: FileInfo[];
}
export interface GetFilesQuery {
  roleId: number;
}
export interface GetGalleryImagesResult {
  images: UploadedImage[];
}
export interface GetGalleryImagesQuery {
  roleId: number;
}
export interface SetMusicPersonaLogoCommand {
  id: number;
  uri: string;
  thumbnail: string;
  container: string;
  reference: string;
}
export interface SetUserAvatarCommand {
  uri: string;
  container: string;
  reference: string;
}
export interface UpdateFileCommand {
  fileId: number;
  usageType: string;
}
export interface UploadedFile {
  id: number;
  name: string;
  uri: string;
  usageType: string;
}
export interface CreateEventDto {
  title: string;
  shortDescription: string;
  description: string;
  meta: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  submissionStartDate: string;
  submissionEndDate: string;
  eventOrganizerId: number;
}
export interface CreateEventSubmission {
  bandId: number;
  note: string;
}
export interface EditEventDto {
  title: string;
  shortDescription: string;
  description: string;
  meta: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  submissionStartDate: string;
  submissionEndDate: string;
}
export interface EventDto {
  id: number;
  title: string;
  shortDescription: string;
  description: string;
  meta: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  submissionStartDate: string;
  submissionEndDate: string;
  eventOrganizerId: number;
}
export interface EventForBandDto {
  id: number;
  title: string;
  shortDescription: string;
  description: string;
  meta: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  submissionStartDate: string;
  submissionEndDate: string;
  eventOrganizerId: number;
  eventSubmissionId: number;
  status: EventSubmissionStatus;
  sentOn: string;
  note: string;
}
export interface EventOrganizerDashboard {}
export interface EventSubmissionDto {
  id: number;
  note: string;
  status: EventSubmissionStatus;
  bandId: number;
  bandName: string;
  bandThumb: string;
  eventId: number;
  eventTitle: string;
  sentOn: string;
}
export interface AcceptClubInvite {
  invitationId: string;
  password: string;
  confirmPassword: string;
}
export interface ClubProfileMemberDto {
  userName: string;
  role: MemberRoleType;
  isAdmin: boolean;
}
export interface ClubProfileDto {
  id: number;
  name: string;
  email: string;
  meta: string;
  info: string;
  logo: string;
  files: UploadedFile[];
  workingDays: ClubWorkingDays;
  firstDayOfWeek: DayOfWeek;
  location: LocationDto;
  members: ClubProfileMemberDto[];
  images: UploadedImage[];
  genres: GenreViewModel[];
  maxCapacity: number;
  hasFacebook: boolean;
  facebookFanCount: number;
}
export interface ConnectClubToFacebookCommand {
  clubId: number;
  facebookId: string;
  fanCount: number;
  link: string;
}
export interface CreateClubCommand {
  name: string;
  genres: number[];
  city: string;
  countryCode: string;
  address: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  allowAccess: boolean;
}
export interface CreateClubFromFacebookCommand {
  name: string;
  genres: number[];
  city: string;
  countryName: string;
  countryCode: string;
  country: string;
  address: string;
  latitude: number;
  longitude: number;
  googlePlaceId: string;
  facebookId: string;
  about: string;
  pictureUrl: string;
  fanCount: number;
  website: string;
  link: string;
}
export interface CreateClubFromVenue {
  venueId: number;
  invitationId: string;
}
export interface CreatedClub {
  id: number;
  genres: GenreViewModel[];
  name: string;
  venueId: number;
}
export interface CreateNotificationWhenClubCreated {}
export interface DeleteClubCommand {
  clubId: number;
}
export interface DisconnectClubFromFacebookCommand {
  clubId: number;
}
export interface GetClubDashboardArtistRequest {
  id: number;
  dates: string;
  sentOn: string;
  state: ArtistRequestState;
  artist: ArtistPersonaDto;
  paymentInfo: ArtistRequestPaymentInfo;
  messageGroupId?: number;
  hasUnread: boolean;
}
export interface GetClubDashboardResult {
  name: string;
  requestsCount: number;
  emailRequired: boolean;
  isVerified: boolean;
  requests: GetClubDashboardArtistRequest[];
  upcoming: GetClubDashboardArtistRequest;
}
export interface GetClubDashboardQuery {
  clubId: number;
}
export interface GetClubInviteDetails {
  invitationId: string;
}
export interface GetClubProfileQuery {
  clubId: number;
}
export interface GetClubRequestResultHistoryResult {
  eventType: ArtistRequestHistoryEventType;
  sentOn: string;
}
export interface GetClubRequestResult {
  requestData: string;
  state: ArtistRequestState;
  artist: PersonaDto;
  dates: string;
  paymentInfo: ArtistRequestPaymentInfo;
  history: GetClubRequestResultHistoryResult[];
  messageGroupId?: number;
  hasUnread: boolean;
}
export interface GetClubRequestQuery {
  clubId: number;
  requestId: number;
}
export interface GetClubRequestsResultRequest {
  id: number;
  state: ArtistRequestState;
  sentOn: string;
  hasUnread: boolean;
  messageGroupId?: number;
  artist: ArtistPersonaDto;
  dates: string;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface GetClubRequestsResult {
  requests: GetClubRequestsResultRequest[];
}
export interface GetClubRequestsQuery {
  clubId: number;
}
export interface InvitationDetails {
  name: string;
  email: string;
  address: string;
  city: string;
  countryName: string;
}
export interface UpdateClubProfileCommand {
  clubId: number;
  name: string;
  email: string;
  meta: string;
  info: string;
  genres: number[];
  maxCapacity: number;
  workingDays: ClubWorkingDays;
  firstDayOfWeek: DayOfWeek;
  location: LocationDto;
}
export interface GetClubImportedRequestResult {
  id: number;
  state: ArtistRequestState;
  date: string;
  artistId: number;
  artistName: string;
  artistEmail: string;
  location: LocationDto;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface GetClubImportedRequestQuery {
  clubId: number;
  requestId: number;
}
export interface ImportArtistRequestAsClubCommand {
  clubId: number;
  date: string;
  artistName: string;
  artistEmail: string;
  location: LocationDto;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface UpdateClubImportedRequestCommand {
  artistRequestId: number;
  clubId: number;
  date: string;
  artistName: string;
  artistEmail: string;
  location: LocationDto;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface GetBookVenueInfoQuery {
  clubId: number;
}
export interface GetBookVenueInfoResult {
  club: PersonaDto;
  error: string;
  requestFormData: string;
}
export interface ConnectBandToFacebookCommand {
  bandId: number;
  facebookId: string;
  fanCount: number;
  link: string;
}
export interface CreateBandCommand {
  name: string;
  genreId: number;
  city: string;
  countryCode: string;
  address: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  allowAccess: boolean;
}
export interface CreateBandFromFacebookCommand {
  name: string;
  genreId: number;
  city: string;
  countryName: string;
  countryCode: string;
  country: string;
  address: string;
  latitude: number;
  longitude: number;
  googlePlaceId: string;
  facebookId: string;
  about: string;
  pictureUrl: string;
  fanCount: number;
  website: string;
  link: string;
}
export interface CreateNotificationWhenArtistCreated {}
export interface DeleteBandCommand {
  bandId: number;
}
export interface DisconnectBandFromFacebookCommand {
  bandId: number;
}
export interface BandDashboardArtistRequest {
  id: number;
  state: ArtistRequestState;
  createdOn: string;
  sentOn: string;
  modifiedOn: string;
  messageGroupId?: number;
  hasUnread: boolean;
  club: PersonaDto;
  dates: string;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface BandDashboardResult {
  hasRider: boolean;
  hasTours: boolean;
  meta: string;
  isVerified: boolean;
  images: UploadedImage[];
  requests: BandDashboardArtistRequest[];
  upcoming: BandDashboardArtistRequest;
  requestsCount: number;
}
export interface GetBandDashboardQuery {
  bandId: number;
}
export interface GetBandProfileQueryResult {
  id: number;
  name: string;
  logo: string;
  isVerified: boolean;
  location: LocationDto;
  bio: string;
  meta: string;
  genres: GenreViewModel[];
  members: Member[];
  hasRider: boolean;
  images: UploadedImage[];
  hasFacebook: boolean;
  facebookFanCount: number;
}
export interface GetBandProfileQuery {
  bandId: number;
}
export interface UpdateBandProfileCommand {
  bandId: number;
  name: string;
  bio: string;
  meta: string;
  genres: GenreViewModel[];
  location: LocationDto;
}
export interface Member {
  userName: string;
  role: MemberRoleType;
  isAdmin: boolean;
}
export interface GetArtistImportedRequestResult {
  id: number;
  state: ArtistRequestState;
  date: string;
  venueId: number;
  venueName: string;
  venueEmail: string;
  venueMaxCapacity: number;
  location: LocationDto;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface GetArtistImportedRequestQuery {
  artistId: number;
  requestId: number;
}
export interface ImportArtistRequestAsArtistCommand {
  artistId: number;
  date: string;
  venueName: string;
  venueEmail: string;
  location: LocationDto;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface UpdateArtistImportedRequestCommand {
  artistRequestId: number;
  artistId: number;
  date: string;
  venueName: string;
  venueEmail: string;
  venueMaxCapacity: number;
  location: LocationDto;
  paymentInfo: ArtistRequestPaymentInfo;
}
export interface ChangePasswordCommand {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
export interface ConfirmAccountCommand {
  token: string;
}
export interface LoginCommand {
  username: string;
  password: string;
  rememberMe: boolean;
}
export interface RegisterUserCommand {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreeNewsletter: boolean;
}
export interface ResendConfirmationEmailCommand {}
export interface ResetPasswordWithTokenCommand {
  email: string;
  password: string;
  confirmPassword: string;
  token: string;
}
export interface SendConfirmationEmailAfterUserRegistered {}
export interface SendResetPasswordEmailCommand {
  email: string;
}
export interface Users {}
export enum RegistrationSource {
  Mail = 0,
  Facebook = 1,
}
export interface JwtTokenDto {
  accessToken: string;
  expiresIn: number;
}
export interface JwtHelper {}
export interface FacebookData<TData> {
  data: TData;
}
export interface FacebookUserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  picture: FacebookData<FacebookPicture>;
}
export interface FacebookPicture {
  height: number;
  width: number;
  isSilhouette: boolean;
  url: string;
}
export interface FacebookGraphClient {}
export interface FacebookAccountDto {
  id: string;
  name: string;
  about: string;
  category: string;
  website: string;
  link: string;
  fanCount: number;
  location: FacebookLocation;
  picture: FacebookData<FacebookPicture>;
}
export interface FacebookLocation {
  city: string;
  country: string;
  street: string;
  zip: string;
  latitude: number;
  longitude: number;
}
export interface FacebookPagesData {
  accounts: FacebookData<FacebookAccountDto[]>;
}
export interface GetFacebookPagesQuery {
  accessToken: string;
}
export interface GetFacebookUserInfoQuery {
  accessToken: string;
  expiresIn: number;
}
export interface FacebookUserInfoResult {
  email: string;
  firstName: string;
  lastName: string;
  pictureUrl: string;
  isLinked: boolean;
  gigPinName: string;
  gigPinAvatarUrl: string;
}
export interface LinkWithFacebookCommand {
  accessToken: string;
  expiresIn: number;
}
export interface LoginWithFacebookCommand {
  accessToken: string;
  expiresIn: number;
}
export interface RegisterWithFacebookCommand {
  accessToken: string;
  expiresIn: number;
  firstName: string;
  lastName: string;
  email: string;
  agreeNewsletter: boolean;
  pictureUrl: string;
}
export interface UnlinkFacebookCommand {}
export interface FacebookAppAccessToken {
  accessToken: string;
}
export interface FacebookUserAccessTokenValidation
  extends FacebookData<FacebookUserAccessTokenData> {}
export interface FacebookUserAccessTokenData {
  appId: number;
  type: string;
  application: string;
  expiresAt: number;
  isValid: boolean;
  userId: number;
}
export interface CreateAndSendRequestDto {
  artistId: number;
  clubId: number;
  data: string;
}
export interface SendRequestChangesDto {
  data: string;
}
export interface ConfirmReviewDto {
  acceptReview: boolean;
  selectedDate: string;
}
export interface ReviewRequestDto {
  data: string;
  privateData: string;
}
export interface AcceptRequestDto {
  date: string;
}
export interface DeclineRequestDto {
  data: string;
}
export interface SendgridParseDto {
  requestId: string;
  message: string;
  from: string;
}
export interface UpdateFileDto {
  usageType: string;
}
export interface SendInviteDto {
  roleId: number;
  email: string;
}
export interface SendMessageDto {
  messageGroupId: number;
  content: string;
  senderId: number;
}
export interface UpdateRiderDto {
  data: string;
}
export interface TourDateInfo {
  date: string;
  countryCode: string;
  countryName: string;
  city: string;
  address: string;
  lat?: number;
  lng?: number;
  radius: number;
  capacity: number;
  fee: number;
  doorDeal: boolean;
  isDayOff: boolean;
  isExternal: boolean;
}
export interface RecentMessageGroupResult {
  messageId: number;
  content: string;
  sentOn: string;
  sentByUser: string;
  groupId: number;
  date: string;
  isRead: boolean;
  isSeen: boolean;
  isDelivered: boolean;
  senderId: number;
  senderLogo: string;
  senderLogoThumb: string;
  senderName: string;
}
export enum PaymentType {
  None = 0,
  DoorDeal = 1,
  FixedPrice = 2,
  Other = 3,
}
export enum ArtistRequestState {
  Draft = 0,
  Pending = 1,
  Pinned = 2,
  ArtistCancelled = 11,
  ClubDeclined = 12,
  ArtistImported = 200,
  ClubImported = 201,
}
export enum ArtistRequestHistoryEventType {
  Unknown = 0,
  ArtistSent = 1,
  ArtistCancelled = 2,
  ArtistUpdatesRequest = 3,
  ClubAccepts = 100,
  ClubDeclined = 101,
  Imported = 1000,
}
export enum EventSubmissionStatus {
  Sent = 0,
  Canceled = 1,
  Declined = 2,
  Accepted = 3,
}
export enum FileType {
  RiderInfo = 0,
  ClubInfo = 1,
}
export enum ImageType {
  None = 0,
  Logo = 1,
  Gallery = 2,
  Avatar = 3,
}
export enum ChatType {
  NotSet = 0,
  Gig = 1,
}
export enum ClubWorkingDays {
  All = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
}
export enum EventType {
  Unknown = 0,
  General = 1,
  Festival = 2,
}
export enum MemberRoleType {
  NotSet = 0,
  Admin = 1,
}
export enum PinStatusType {
  NotSet = 0,
  Planned = 1,
  Pending = 2,
  AcceptedByClub = 4,
  RejectedByClub = 5,
  RejectedByUser = 6,
  Pinned = 7,
  Removed = 8,
}
export enum VenueType {
  Venue = 0,
  MusicShop = 1,
}
export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}
