import React, { useState } from 'react';

type Props = {
  isOpen?: boolean;
  children: (
    isOpen: boolean,
    show: () => void,
    hide: () => void
  ) => React.ReactNode;
};

/**
 * @example
 * <WithDialog>
 *     {(isOpen, show, hide) => <></>}
 * </WithDialog>
 *
 * @export
 * @class WithDialog
 * @extends {PureComponent<{}, { isOpen: boolean }>}
 */
export function WithDialog(props: Props) {
  const [isOpen, setIsOpen] = useState(!!props.isOpen);

  const show = (e: any) => {
    if (!!e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    setIsOpen(true);
  };

  const hide = () => setIsOpen(false);

  const children: any = props.children;
  return children(isOpen, show, hide);
}
