import React from 'react';
import c from 'classnames';

export const FacebookIcon = props => (
  <span
    {...props}
    className={c('socicon socicon-facebook', props.className)}
    aria-hidden="true"
    title="Link to the Facebook profile"
  />
);

export const YoutubeIcon = () => (
  <span
    className="socicon socicon-youtube"
    aria-hidden="true"
    title="Link to the Youtube profile"
  />
);

export const TwitterIcon = () => (
  <span
    className="socicon socicon-twitter"
    aria-hidden="true"
    title="Link to the Twitter profile"
  />
);

export const InstagramIcon = () => (
  <span
    className="socicon socicon-instagram"
    aria-hidden="true"
    title="Link to the Instagram profile"
  />
);

export const SoundcloudIcon = () => (
  <span
    className="socicon socicon-soundcloud"
    aria-hidden="true"
    title="Link to the Soundcloud profile"
  />
);

export const BandcampIcon = () => (
  <span
    className="socicon socicon-bandcamp"
    aria-hidden="true"
    title="Link to the Bandcamp profile"
  />
);
export const SpotifyIcon = () => (
  <span
    className="socicon socicon-spotify"
    aria-hidden="true"
    title="Link to the Spotify profile"
  />
);
