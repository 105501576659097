import React from 'react';
import styled, { css } from 'styled-components/macro';

export type ButtonProps = {
  variant?: 'primary' | 'discrete' | 'danger' | 'blue-text';
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

// TODO: make this default Button
export const Button = styled.button<ButtonProps>`
  letter-spacing: 0.02em;
  border: 1px solid var(--primary);
  background-color: transparent;
  color: white;
  border-radius: 3px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;

  min-height: 35px;
  padding: 0 20px;

  ${(props) =>
    props.variant === 'discrete' &&
    css`
      border: none;
      padding: 0;

      min-width: initial;
      min-height: initial;

      :hover {
        color: var(--accent);
      }
    `}
  ${(props) =>
    props.variant === 'primary' &&
    css`
      background-color: var(--primary);
      color: white;

      :hover {
        background-color: var(--main-color);
        border: 1px solid var(--primary);
      }

      :disabled {
        background: var(--disabled);
        border: 1px solid var(--disabled);
        color: #3c4f72;
      }

      @media screen and (min-width: 640px) {
        min-width: 100px;
      }

      @media screen and (min-width: 768px) {
        min-width: 146px;
      }
    `}
  ${(props) =>
    props.variant === 'danger' &&
    css`
      background-color: var(--alert-error);
      border: none;

      :hover {
        color: #efefef;
      }

      @media screen and (min-width: 640px) {
        min-width: 100px;
      }

      @media screen and (min-width: 768px) {
        min-width: 146px;
      }
    `}

  ${(props) =>
    props.variant === 'blue-text' &&
    css`
      border: none;
      padding: 0;
      color: var(--main-color);
      min-width: initial;
      min-height: initial;
    `}

  ${(props) =>
    !props.variant &&
    css`
      :hover {
        color: #0178ce;
      }
    `}

  :disabled {
    color: var(--disabled-txt);
  }
`;

export const FacebookButton = styled(Button)`
  background-color: #3b5998;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 1em;

  padding: 0px 12px;
  border: none;
  width: 100%;

  :hover {
    color: #eaeaea;
    box-shadow: inset 0 1px 2px 1px rgba(0, 0, 0, 0.8);
  }
`;
