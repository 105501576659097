import React from 'react';
import c from 'classnames';

import { useTitle } from 'utils/useTitle';

type Props = {
  className?: string;
  variant?: 'narrow' | 'content' | 'fullscreen';
  title: string;
};

export function Page(props: React.PropsWithChildren<Props>) {
  const { title, className, variant, children } = props;

  useTitle(title);

  return (
    <div
      className={c(
        {
          'p-5 md:p-10 xxl:w-3/4 xxl:mx-auto space-y-xxlarge':
            variant !== 'fullscreen',
        },
        {
          'max-w-4xl w-full mx-auto mb-10': variant === 'content',
        },
        {
          'max-w-2xl w-full mx-auto mb-10': variant === 'narrow',
        },
        className
      )}
    >
      {children}
    </div>
  );
}

function PageTitle(
  props: React.PropsWithChildren<{
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLHeadingElement>) => void;
  }>
) {
  const { children, className, ...rest } = props;

  return (
    <h1 className={className} {...rest}>
      {children}
    </h1>
  );
}

function PageSection({
  className,
  children,
}: React.PropsWithChildren<{ className?: string }>) {
  return <section className={className}>{children}</section>;
}

Page.Section = PageSection;
Page.Title = PageTitle;
