import styled from 'styled-components';

export const ActiveIcons = styled.div`
  width: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  > a {
    margin-bottom: 20px;
    height: 46px;
    min-height: 46px;
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  > .active {
    background-color: rgba(33, 156, 245, 0.3);
    width: 60px;
    border-radius: 3px;
  }
`;
