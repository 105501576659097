import React from 'react';
import { Link } from 'react-router-dom';

import { translations } from './translations';

enum IdentityErrors {
  PasswordRequiresDigit = 'PasswordRequiresDigit',
  PasswordRequiresLower = ' PasswordRequiresLower',
  DuplicateUserName = 'DuplicateUserName',
}

export function translateError(message: string): React.ReactNode {
  message = (message || '').trim();
  if (
    message.startsWith(IdentityErrors.PasswordRequiresDigit) ||
    message.startsWith(IdentityErrors.PasswordRequiresLower)
  ) {
    return 'Please ensure password is 8 characters long and contains at least one digit and one lowercase character';
  } else if (message.startsWith(IdentityErrors.DuplicateUserName)) {
    return (
      <>
        A user with that e-mail address already exists. If you forgot your
        password, you can{' '}
        <Link to="/forgot-password">reset your password here</Link>.
      </>
    );
  }

  return translations[(message || '').toLowerCase()] || message;
}
