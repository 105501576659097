import React from 'react';

import { Page } from 'ui';
import { WelcomeLogo } from 'ui/welcome-logo';

export default function ErrorPage({ children }: React.PropsWithChildren<{}>) {
  return (
    <Page variant="narrow" title="Error">
      <div className="bg-boxed p-12 mt-12 rounded-lg space-y-large">
        <WelcomeLogo />
        <Page.Title>Uh-oh</Page.Title>

        <p className="pb-3">
          Something went wrong! Our tech crew will be notified and dispatched
          immediately to solve the issue!
          <br />
          Meanwhile we suggest you practice those riffs...
        </p>

        {children}
      </div>
    </Page>
  );
}
