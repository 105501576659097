import React from 'react';
export function IconCaret(props: any) {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.574219 11.078L5.82052 5.83171L0.574219 0.588287"
        stroke="#219CF5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
