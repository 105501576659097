import { _upload, _delete, _put } from './common';
import { FileType, UploadedFile } from './types';

export function uploadFile(
  file: any,
  type: FileType,
  ownerId: number,
  onUploadProgress?: (progress: number) => void
) {
  const target =
    type === FileType.RiderInfo
      ? 'rider'
      : type === FileType.ClubInfo
      ? 'clubinfo'
      : '';

  const data = new FormData();
  data.append('file', file);

  return _upload<UploadedFile>(
    `files/${target}/${ownerId}`,
    data,
    onUploadProgress
  );
}

export function deleteFile(id: number) {
  return _delete(`files/${id}`);
}

export function updateUsageType(fileId: string | number, usageType: string) {
  return _put(`files/${fileId}`, {
    usageType,
  });
}
