import React from 'react';

export default function Logo(props: any) {
  return (
    <svg
      width="39"
      height="42"
      viewBox="0 0 39 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.96013 2.16077H9.83649L4.73438 9.17537L4.96013 2.16077Z"
        fill="#75211C"
      />
      <path
        d="M25.4141 34.6258L32.1416 35.0305L33.0447 32.4225L27.491 30.489L25.4141 34.6258Z"
        fill="#75211C"
      />
      <path
        d="M37.0615 6.61231C34.6685 2.61039 29.747 0.811775 24.0128 0.227225C22.9743 0.0923287 21.8455 0.0473633 20.7167 0.0473633C19.5879 0.0473633 18.4591 0.0923287 17.4206 0.227225C11.6864 0.901706 6.71975 2.65536 4.37188 6.61231C1.9337 10.6142 3.24309 16.5946 5.0943 20.7314C11.1446 34.4459 19.0009 41.4155 20.7167 42.0001C22.4325 41.3256 30.334 34.4459 36.3391 20.7314C38.1452 16.5047 39.4997 10.6142 37.0615 6.61231ZM20.7167 27.0716C14.26 27.0716 8.97733 21.8106 8.97733 15.3806C8.97733 8.95051 14.26 3.68956 20.7167 3.68956C27.1734 3.68956 32.4561 8.95051 32.4561 15.3806C32.4561 21.8106 27.1734 27.0716 20.7167 27.0716Z"
        fill="#219CF5"
      />
      <path
        d="M20.3117 20.1919C22.9799 20.1919 25.1429 18.0378 25.1429 15.3806C25.1429 12.7234 22.9799 10.5693 20.3117 10.5693C17.6435 10.5693 15.4805 12.7234 15.4805 15.3806C15.4805 18.0378 17.6435 20.1919 20.3117 20.1919Z"
        fill="white"
      />
      <path
        d="M4.95954 2.16077L36.9719 24.2388L32.1407 35.0305L0.625 13.582L4.95954 2.16077Z"
        fill="#AB3623"
      />
      <path
        d="M11.0072 10.7042L12.8584 11.9183C13.31 12.233 13.5809 12.5928 13.6712 12.9525C13.7163 13.2673 13.6712 13.582 13.4906 13.8068C13.2196 14.2115 12.7681 14.3464 12.4069 14.3015C12.7681 14.7961 12.8584 15.2457 12.4972 15.7853C12.0006 16.5048 11.1878 16.4598 10.33 15.8752L8.43359 14.6162L11.0524 10.7492L11.0072 10.7042ZM12.5424 13.3572C12.723 13.0874 12.6327 12.8176 12.2715 12.5478L11.4136 11.9633L10.8718 12.8626L11.6845 13.4021C11.9554 13.6719 12.3166 13.7169 12.5424 13.3572ZM11.3233 14.2565L10.33 13.582L9.74299 14.4364L10.7815 15.1108C11.1427 15.3806 11.5039 15.3806 11.6845 15.1108C11.8651 14.7961 11.7748 14.5263 11.3233 14.2565Z"
        fill="white"
      />
      <path
        d="M16.111 14.1666L19.091 16.1451L18.5943 16.9095L16.4722 15.4706L15.9304 16.28L17.8267 17.539L17.3301 18.3034L15.4337 17.0444L14.8467 17.8988L17.014 19.3376L16.5173 20.1021L13.4922 18.0786L16.111 14.1666Z"
        fill="white"
      />
      <path
        d="M21.2549 18.798L20.0358 17.9437L20.5777 17.1343L23.8737 19.3376L23.3319 20.147L22.1128 19.3376L19.9907 22.4852L19.1328 21.9006L21.2549 18.798Z"
        fill="white"
      />
      <path
        d="M26.4522 21.0463L27.2649 21.5858L26.2264 26.6669L25.3234 26.0824L25.5492 24.9583L23.9237 23.8341L22.9304 24.4636L22.0273 23.8791L26.4522 21.0463ZM25.7749 23.924L26.1361 22.3053L24.7816 23.2046L25.7749 23.924Z"
        fill="white"
      />
    </svg>
  );
}
