import React from 'react';

export default function IconX(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.07812 0.513794L15.3326 14.7683"
        stroke="#219CF5"
        strokeMiterlimit="10"
      />
      <path
        d="M1.07812 14.7683L15.3326 0.513794"
        stroke="#219CF5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
