import {
  JwtTokenDto,
  ChangePasswordCommand,
  LoginCommand,
  ConfirmAccountCommand,
} from './types';
import { _post } from './common';

export const getToken = (
  username: string,
  password: string,
  rememberMe: boolean
) => {
  return _post<JwtTokenDto>(`user/login`, {
    username,
    password,
    rememberMe,
  } as LoginCommand);
};

export function changePassword(data: ChangePasswordCommand) {
  return _post(`user/change-password`, data);
}

export function resendConfirmationMail() {
  return _post(`user/resend-confirmation-email`);
}

export function confirmAccount(token: string) {
  return _post(`user/confirm-account`, {
    token,
  } as ConfirmAccountCommand);
}
