import React, { useState, useEffect, useRef } from 'react';

import './Loading.css';

export interface LoadingProps {
  style?: object;
  delayed?: boolean;
}

export const Loading = ({ style, delayed }: LoadingProps) => {
  const mounted = useRef(false);
  const [show, setShow] = useState(!delayed);

  useEffect(() => {
    mounted.current = true;

    setTimeout(() => {
      if (mounted.current) {
        setShow(true);
      }
    }, 500);

    return () => {
      mounted.current = false;
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className="loading" style={style}>
      <div className="loading-spinner" />
    </div>
  );
};

export const LoadingOverlay = ({ style }: LoadingProps) => (
  <div className="loading-overlay">
    <Loading style={style} delayed />
  </div>
);
