import React from 'react';

type Props = {
  href: string;
  className?: string;
  title?: string;
  download?: any;
  rel?: string;
  target?: string;
};

export function ExternalLink({
  href,
  children,
  ...rest
}: React.PropsWithChildren<Props>) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  );
}
