const key = 'remember.club';

export function rememberClub(id: number) {
  if (!id) {
    forgetRememberedClub();
  } else {
    localStorage.setItem(key, id.toString());
  }
}

export function getRemembered() {
  const item = localStorage.getItem(key);
  if (item === null) {
    return 0;
  }
  return parseInt(item);
}

export function forgetRememberedClub() {
  localStorage.removeItem(key);
}
