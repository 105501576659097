import React from 'react';

import IconBandcamp from './bandcamp.svg';
import IconFacebook from './facebook.svg';
import IconInstagram from './instagram.svg';
import IconSoundcloud from './soundcloud.svg';
import IconTwitter from './twitter.svg';
import IconWeb from './web.svg';
import IconYoutube from './youtube.svg';

import IconX from './x';
import IconGlobe from './icon-globe';
import IconOpen from './icon-open';

import RemoveCircled from './remove-circled.svg';

export {
  IconBandcamp,
  IconFacebook,
  IconInstagram,
  IconSoundcloud,
  IconTwitter,
  IconWeb,
  IconYoutube,
  IconX,
  IconGlobe,
  IconOpen,
};

export function IconRemoveCircled() {
  return <img src={RemoveCircled} alt="" />;
}

export function IconTrash(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg version="1.1" viewBox="0 0 383.6 383.6" {...props} fill="currentColor">
      <path d="m255.8 112h-12c-4.4 0-8 3.6-8 8s3.6 8 8 8h12c4.4 0 8-3.6 8-8s-3.6-8-8-8z" />
      <path d="m315.8 48h-48c0-26.4-21.6-48-48-48h-60c-26.4 0-48 21.6-48 48h-44c-22 0-40 18-40 40v16c0 12 9.2 22 20.8 23.6l20.4 219.6c2 20.8 19.2 36.4 40 36.4h165.6c20.8 0 38-15.6 40-36.4l20.4-219.6c11.6-1.6 20.8-11.6 20.8-23.6v-16c0-22-18-40-40-40zm-156-32h60c17.6 0 32 14.4 32 32h-124c0-17.6 14.4-32 32-32zm180 88c0 4.4-3.6 8-8 8h-4c-4 0-7.6 3.2-8 7.2l-21.2 226.8c-1.2 12.4-11.6 22-24 22h-165.6c-12.4 0-22.8-9.6-24-22l-20.4-218h143.2c4.4 0 8-3.6 8-8s-3.6-8-8-8h-156c-4.4 0-8-3.6-8-8v-16c0-13.2 10.8-24 24-24h248c13.2 0 24 10.8 24 24v16z" />
      <path d="m127.8 160c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8s8-3.6 8-8v-152c0-4.4-3.6-8-8-8z" />
      <path d="m191.8 160c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8s8-3.6 8-8v-152c0-4.4-3.6-8-8-8z" />
      <path d="m255.8 160c-4.4 0-8 3.6-8 8v152c0 4.4 3.6 8 8 8s8-3.6 8-8v-152c0-4.4-3.6-8-8-8z" />
    </svg>
  );
}

export function CheckIcon(props) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.94864 16.6155L0.0546875 9.43684L1.54066 7.98388L6.74158 13.3894L16.0228 0.87915L17.6793 2.1351L6.94864 16.6155Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function IconSmallArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.18103 1L9.63807 9.31399L1.18103 17.6442"
        stroke="#383838"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export function IconChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6787 13.9309C11.2383 14.9816 9.74309 15.7312 8.81563 16.1491L9.09115 14.7084L9.20287 14.1242L8.60816 14.1145L8.40184 14.1112C8.40132 14.1112 8.4008 14.1112 8.40028 14.1111C4.00066 14.0122 0.613281 11.0857 0.613281 7.79423C0.613281 4.40779 4.09955 1.474 8.62532 1.474C13.1511 1.474 16.6374 4.40779 16.6374 7.79423C16.6374 10.3143 14.7869 12.3931 12.6787 13.9309Z"
        stroke="currentColor"
      />
    </svg>
  );
}
