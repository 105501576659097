import React from 'react';
import styled, { css } from 'styled-components/macro';

import { SpacingType } from 'ui/atoms/types';

function spaceToPx(space?: SpacingType) {
  switch (space) {
    case 'none':
      return '0';
    case 'micro':
      return '2px';
    case 'small':
      return '5px';
    case 'medium':
      return '10px';
    case 'large':
      return '20px';
  }

  return '0';
}

type BoxInternalProps = {
  space?: SpacingType;
  background?: string;
};

const BoxInternal = styled.div<BoxInternalProps>`
  display: flex;

  ${(props) =>
    !!props.space &&
    css`
      padding: ${spaceToPx(props.space)};
    `}

  ${(props) =>
    !!props.background &&
    css`
      background: ${props.background};
    `}
`;

type BoxProps = BoxInternalProps & {
  className?: string;
};

export function Box({ children, ...rest }: React.PropsWithChildren<BoxProps>) {
  return <BoxInternal {...rest}>{children}</BoxInternal>;
}
