import React, { useState, useEffect, useCallback } from 'react';

import { MeViewModel, MyPersonaViewModel } from 'external/api';
import { useMedia } from 'HelperFunctions/use-media';
import { IconX } from 'icons';

import SidebarRoles from './sidebar-roles';
import IconNewRole from './icon-new-role';
import IconuUserSettings from './icon-user-settings';
import IconSignout from './icon-sign-out';
import { IconCaret } from './icon-caret';
import {
  OpenButtonMobile,
  OpenButton,
  Sidebar,
  SignedIn,
  UserName,
  AvatarUrl,
  MenuLinks,
  MenuLink,
  IconWrapper,
} from './sidebar-components';
import { MobileMenu } from './mobile-menu';
import { defaultUserLogo } from 'ui';

type RoleDropdownProps = {
  userInfo: MeViewModel;
  currentRole?: MyPersonaViewModel;
  activeUserId: number;
  onSwitchRole: (id: number) => void;
};

export function RoleDropdown({
  userInfo,
  currentRole,
  onSwitchRole,
}: RoleDropdownProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = useCallback(() => {
    setIsExpanded(false);
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
  const isMobile = useMedia(['(max-width: 767px)'], [true], false);

  function toggle(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      window.addEventListener('click', handleClick);
    } else {
      window.removeEventListener('click', handleClick);
    }
  }

  const memberOf = userInfo.memberOf || [];
  const bands = memberOf.filter((m) => m.type === 'band');
  const clubs = memberOf.filter((m) => m.type === 'club');

  return (
    <>
      {isMobile ? (
        <OpenButtonMobile
          id="role-dropdown"
          type="button"
          aria-haspopup="true"
          aria-expanded={isExpanded}
          onClick={toggle}
          open={isExpanded}
          css={`
            -webkit-appearance: none;
            -moz-appearance: none;
          `}
        />
      ) : (
        <OpenButton
          id="role-dropdown"
          type="button"
          aria-haspopup="true"
          aria-expanded={isExpanded}
          onClick={toggle}
          open={isExpanded}
        >
          {isExpanded ? <IconX /> : <IconCaret />}
        </OpenButton>
      )}
      <Sidebar
        open={isExpanded}
        className="role-dropdown header__roles"
        aria-labelledby="role-dropdown"
      >
        <SignedIn>
          Signed in as<UserName>{userInfo.name} </UserName>
          <AvatarUrl src={userInfo.avatarUrl || defaultUserLogo} alt="" />
        </SignedIn>

        {!!currentRole && isMobile && <MobileMenu currentRole={currentRole} />}

        <SidebarRoles title="Bands" onSwitch={onSwitchRole} roles={bands} />
        <SidebarRoles title="Clubs" onSwitch={onSwitchRole} roles={clubs} />
        <MenuLinks>
          <MenuLink to="/account-setup" className="header-user-settings">
            <IconWrapper>
              <IconNewRole />
            </IconWrapper>{' '}
            Add new role
          </MenuLink>
          <MenuLink to="/settings" className="header-user-settings">
            <IconWrapper>
              <IconuUserSettings />
            </IconWrapper>{' '}
            User settings
          </MenuLink>
          <MenuLink to="/logout">
            <IconWrapper>
              <IconSignout />
            </IconWrapper>{' '}
            Sign out
          </MenuLink>
        </MenuLinks>
      </Sidebar>
    </>
  );
}
