import React from 'react';
import { Route, Switch } from 'react-router-dom';

import App from './App';
import NoMatch from './pages/404-page';
import Logout from './pages/app/logout';
import ProfileConnectFacebookPage from 'pages/profile-connect-facebook-page';

import * as pages from './pages';
import { DeveloperErrorPage } from 'pages/_dev/developer-error-page';

function DeveloperDebugPage() {
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  return (
    <div className="container mx-auto max-w-md w-full">
      <h1>Dev stuff</h1>

      <ul>
        {Object.keys(process.env).map((key) => (
          <li>
            <code>{key}</code>: {process.env[key]}
          </li>
        ))}
      </ul>
    </div>
  );
}

function AppRoutes(props) {
  return (
    <App {...props}>
      <Switch>
        <Route exact path="/logout" component={Logout} />

        <Route exact path="/~" component={DeveloperDebugPage} />
        <Route exact path="/~/error" component={DeveloperErrorPage} />

        <Route
          exact
          path="/facebook-auth"
          component={pages.FacebookLoginPage}
        />

        <Route exact path="/" component={pages.DashboardPage} />

        <Route
          exact
          path="/confirm-account"
          component={pages.ConfirmAccountPage}
        />
        <Route
          exact
          path="/confirm-account-success"
          component={pages.ConfirmAccountSuccessPage}
        />

        <Route exact path="/profile" component={pages.ProfilePage} />
        <Route
          exact
          path="/profile/information"
          component={pages.ProfilePage}
        />
        <Route exact path="/profile/images" component={pages.ProfilePage} />
        <Route exact path="/profile/videos" component={pages.ProfilePage} />
        <Route exact path="/profile/contacts" component={pages.ProfilePage} />
        <Route
          exact
          path="/profile/information/connect-facebook"
          component={ProfileConnectFacebookPage}
        />
        <Route exact path="/profile/rider" component={pages.ProfilePage} />
        <Route exact path="/profile/manage" component={pages.ProfilePage} />
        <Route exact path="/profile/:id" component={pages.PublicProfilePage} />
        <Route exact path="/profile/:id/book" component={pages.BookVenuePage} />
        <Route
          exact
          path="/verify-account"
          component={pages.VerifyAccountPage}
        />
        <Route
          exact
          path="/notifications"
          component={pages.NotificationsPage}
        />

        <Route exact path="/messages" component={pages.MessagesPage} />
        <Route exact path="/messages/unread" component={pages.MessagesPage} />
        <Route exact path="/messages/:id?" component={pages.MessagePage} />
        <Route exact path="/settings" component={pages.Settings} />
        <Route
          exact
          path="/settings/link-facebook"
          component={pages.SettingsLinkFacebook}
        />

        <Route path="/welcome" component={pages.WelcomePage} />
        <Route
          path="/account-setup/:step?/:subStep?"
          component={pages.AccountSetupContainer}
        />

        <Route
          exact
          path="/acceptinvite/:invitation"
          component={pages.AcceptInvite}
        />
        <Route
          exact
          path="/clubsetupcomplete"
          component={pages.ClubSetupComplete}
        />

        {/* Club artist requests */}
        <Route path="/request/:requestId" component={pages.ClubRequestPage} />
        <Route path="/requests" component={pages.ClubRequestsPage} />

        {/* Artist's view on requests */}
        <Route
          path="/artist-request/:requestId"
          component={pages.ArtistRequestPage}
        />
        <Route path="/artist-requests" component={pages.ArtistRequestsPage} />

        <Route exact path="/newsletter" component={pages.Newsletter} />

        <Route path="/club-integration" component={pages.ClubIntegration} />
        <Route path="/venue-search" component={pages.VenueSearchPage} />

        <Route component={NoMatch} />
      </Switch>
    </App>
  );
}

export const routes = <AppRoutes />;

// function isNumber(s: string) {
//   const n = Number(s);
//   return n > 0 && +s === n;
// }
