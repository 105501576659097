import { _get, _post, _delete, _put } from './common';
import { RiderForBand, SendTestRiderCommand, UpdateRiderDto } from './types';

export const RIDER_SEND_TEST = 'RIDER_SEND_TEST';
export const RIDER_DELETE = 'RIDER_DELETE';

export function getRider(bandId: number) {
  return _get<RiderForBand>(`riders/band/${bandId}`);
}

export function updateRider(bandId: number, riderData: any) {
  return _put<boolean>(`riders/band/${bandId}`, {
    data: JSON.stringify(riderData),
  } as UpdateRiderDto);
}

export function sendTestRider(bandId: number, email: string) {
  return _post(`riders/band/${bandId}/test`, {
    bandId,
    email,
  } as SendTestRiderCommand);
}

export function deleteRider(bandId: number) {
  return _delete(`riders/band/${bandId}`);
}
