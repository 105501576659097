import React from 'react';
import styled from 'styled-components/macro';

import { defaultUserLogo } from 'ui';

const GradientWrapper = styled.div<{ bgColor: string }>`
  position: relative;
  background: ${(props) => props.bgColor};
  border-radius: 100%;
  min-width: 50px;
  width: 50px;
  height: 50px;
`;

const BackgroundFiller = styled.div<{ background: string }>`
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: ${(props) => props.background};
  border-radius: 100%;
  width: calc(50px - 2px);
  height: calc(50px - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RoundedImage = styled.img`
  border-radius: 50%;
  max-width: 40px;
`;

const bandBackground = 'linear-gradient(to bottom, #a90da7, #591EA0)';
const clubBackground = 'linear-gradient(to bottom, #219CF5, #0F4564)';
const imported = 'white';

type Props = {
  color: string;
  logoUrl: string;
  background?: string;
  isImported?: boolean;
  size?: 'normal' | 'large';
};

export function Logo({ color, logoUrl, background, size }: Props) {
  return (
    <GradientWrapper bgColor={color}>
      <BackgroundFiller background={background || 'rgba(27, 31, 46, 0.99);'}>
        <RoundedImage src={logoUrl || defaultUserLogo} alt="" />
      </BackgroundFiller>
    </GradientWrapper>
  );
}

export function BandLogo(props: Omit<Props, 'color'>) {
  return <Logo {...props} color={bandBackground} />;
}

export function ClubLogo(props: Omit<Props, 'color'>) {
  return <Logo {...props} color={clubBackground} />;
}

export function RoleLogo(props: {
  type: 'band' | 'club' | string | null;
  logoUrl: string;
  background?: string;
  isImported?: boolean;
}) {
  const { type, logoUrl, background } = props;

  if (props.isImported) {
    return <Logo logoUrl={logoUrl} color={imported} background={background} />;
  }

  if (type === 'band') {
    return <BandLogo logoUrl={logoUrl} background={background} />;
  }

  if (type === 'club') {
    return <ClubLogo logoUrl={logoUrl} background={background} />;
  }

  if (!type) {
    return <Logo logoUrl={logoUrl} color="white" background={background} />;
  }

  return null;
}
