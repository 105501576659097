import React, { useState, useContext, createContext } from 'react';

export type NotificationType = 'info' | 'success' | 'warning' | 'danger';

type Notification = {
  id: number;
  type: NotificationType;
  text: string;
};

export type ShowNotificationFunction = (
  type: NotificationType,
  text: string,
  timeout?: number
) => number;

type NotificationsContextProps = {
  notifications: Notification[];
  show: ShowNotificationFunction;
  close: (id: number) => void;
  notifySuccess: (text: string) => any;
  notifyError: (text: string) => any;
  notifyInfo: (text: string) => any;
};

const NotificationsContext = createContext<NotificationsContextProps>(
  {} as any
);

let nextId = 1;

function NotificationsProvider({ children }: React.PropsWithChildren<{}>) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  function show(type: NotificationType, text: string, timeout?: number) {
    const id = nextId++;
    setNotifications(all => [
      ...all,
      {
        id,
        type,
        text,
      },
    ]);

    if (timeout !== -1) {
      setTimeout(() => {
        close(id);
      }, timeout || 5000);
    }

    return id;
  }

  function close(id: number) {
    setNotifications(notifications =>
      notifications.filter(notification => notification.id !== id)
    );
  }

  function notifyError(text: string) {
    show('danger', text);
  }

  function notifySuccess(text: string) {
    show('success', text);
  }

  function notifyInfo(text: string) {
    show('info', text);
  }

  const value = {
    notifications,
    show,
    close,
    notifySuccess,
    notifyError,
    notifyInfo,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

function useNotifications() {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('This component is not under NotificationsProvider');
  }

  return context;
}

export { NotificationsProvider, useNotifications };
