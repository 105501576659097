import React from 'react';

export default function IconMessages() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54127 14.9611L8.75641 14.9648L8.29275 17.539C9.19781 17.1644 17.5287 13.5738 17.5287 7.52409C17.5287 3.42168 13.6044 0.083374 8.78237 0.083374C3.96037 0.083374 0.0360107 3.42168 0.0360107 7.52409C0.0360107 11.5152 3.8491 14.8498 8.54127 14.9611Z"
        fill="currentColor"
      />
    </svg>
  );
}
