import React from 'react';

export default function IconIntegrate(props: any) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      stroke="currentColor"
      fill="none"
    >
      <path d="M29.9492 0.740356H14.8498C7.08038 0.740356 0.781986 7.03875 0.781986 14.8082V15.324C0.781986 23.3783 7.31129 29.9076 15.3656 29.9076C23.4199 29.9076 29.9492 23.3783 29.9492 15.324V0.740356Z" />
      <path
        d="M19.6289 11.2628L23.7004 15.3133L19.6289 19.3638"
        strokeWidth="2.4225"
        strokeMiterlimit="10"
      />
      <path
        d="M11.1027 19.3638L7.03125 15.3133L11.1027 11.2628"
        strokeWidth="2.4225"
        strokeMiterlimit="10"
      />
      <path
        d="M17.0894 10.2145L13.6406 20.4122"
        strokeWidth="2.4225"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
