import { lazy } from 'react';

export const ForgotPasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "forgot-password" */ './forgot-password/forgot-password-page'
  )
);
export const ResetPasswordPage = lazy(() =>
  import(/* webpackChunkName: "reset-password" */ './reset-password-page')
);
export const ConfirmAccountPage = lazy(() =>
  import(/* webpackChunkName: "confirm-account" */ './confirm-account-page')
);
export const ConfirmAccountSuccessPage = lazy(() =>
  import(
    /* webpackChunkName: "confirm-account-success" */ './confirm-account-success-page'
  )
);

export const ClubIntegration = lazy(() =>
  import(
    /* webpackChunkName: "club-integration" */ './club-integration/club-integration-switcher-page'
  )
);
export const VenueSearchPage = lazy(() =>
  import(/* webpackChunkName: "venue-search" */ './venue-search-page')
);
export const MessagesPage = lazy(() =>
  import(/* webpackChunkName: "messages" */ './messages-page')
);
export const MessagePage = lazy(() =>
  import(/* webpackChunkName: "messages" */ './message-page')
);
export const PublicProfilePage = lazy(() =>
  import(
    /* webpackChunkName: "public-profile" */ './public-profile/public-profile-page'
  )
);
export const VerifyAccountPage = lazy(() =>
  import(/* webpackChunkName: "verify-account" */ './verify-account-page')
);
export const RegisterPage = lazy(() =>
  import(/*webpackChunkName: "register-page" */ './register-page')
);
export const ClubInviteRegister = lazy(() =>
  import(/*webpackChunkName: "ClubInviteRegister" */ './club-invitation-page')
);
export const RegisterClubContainer = lazy(() =>
  import(
    /*webpackChunkName: "RegisterClubContainer" */ './RegisterClubContainer'
  )
);
export const DashboardPage = lazy(() =>
  import(/*webpackChunkName: "dashboard" */ './dashboard-page')
);
export const BookVenuePage = lazy(() =>
  import(/*webpackChunkName: "BookVenuePage" */ './book-venue/book-venue-page')
);
export const FacebookLoginPage = lazy(() =>
  import(/*webpackChunkName: "FacebookLoginPage" */ './facebook-login-page')
);
export const SettingsLinkFacebook = lazy(() =>
  import(
    /*webpackChunkName: "settings-link-facebook" */ './settings-link-facebook-page'
  )
);

export const ClubRequestPage = lazy(() =>
  import(/*webpackChunkName: "club-request-page"*/ 'pages/club-request-page')
);

export const ArtistRequestsPage = lazy(() =>
  import(
    /*webpackChunkName: "artist-requests-page"*/ 'pages/artist-requests/artist-requests-page'
  )
);
export const ClubRequestsPage = lazy(() =>
  import(
    /*webpackChunkName: "club-requests-page"*/ 'pages/club-requests/club-requests-page'
  )
);

export const ArtistRequestPage = lazy(() =>
  import('pages/artist-request-page')
);

export const NotificationsPage = lazy(() =>
  import(
    /* webpackChunkName: "notifications" */ 'pages/notifications/notifications-page'
  )
);

export const Settings = lazy(() =>
  import(/* webpackChunkName: "settings" */ 'pages/settings/settings-page')
);
export const Newsletter = lazy(() =>
  import(/* webpackChunkName: "newsletter" */ 'pages/newsletter-page')
);
export const AcceptInvite = lazy(() =>
  import(
    /* webpackChunkName: "accept-invite" */ 'pages/account-setup/legacy/AcceptInvite'
  )
);
export const ClubSetupComplete = lazy(() =>
  import(
    /* webpackChunkName: "club-setup-complete" */ 'pages/account-setup/legacy/ClubSetupComplete'
  )
);
export const AccountSetupContainer = lazy(() =>
  import(
    /* webpackChunkName: "account-setup-container" */ 'pages/account-setup-page'
  )
);
export const ProfilePage = lazy(() =>
  import(/* webpackChunkName: "profile" */ 'pages/profile-page')
);

export const WelcomePage = lazy(() =>
  import(/* webpackChunkName: "welcome" */ 'pages/account-setup/welcome-page')
);
